import React from 'react'
import { Tag } from 'antd'

export const renderStatusTag = (status) => {
    if (status === 0) {
        return (
            <Tag color="orange">Pending</Tag>
        )
    }
    else if (status === 1) {
        return (
            <Tag color="blue">Approved</Tag>
        )
    }
    else if (status === 2) {
        return (
            <Tag color="green">Accepted</Tag>
        )
    }
    else {
        return (
            <Tag color="red">Declined</Tag>
        )
    }
}
export const renderPaymentStatus = (status) => {
    if (status === 0) {
        return (
            <Tag color="orange">Pending</Tag>
        )
    }
    else if (status === 1) {
        return (
            <Tag color="green">Approved</Tag>
        )
    }
    else {
        return (
            <Tag color="red">Declined</Tag>
        )
    }
}