import React, { useContext, useState } from 'react'
import { Form, Icon, Input, Button, Card, Typography } from 'antd';
import { Link } from 'react-router-dom'
import axios from 'axios'
import Header2 from './Header2'
import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext';
const { Text } = Typography;
const { Title } = Typography;

const Login = () => {
    const { dispatch, loading, setLoading, setError } = useContext(AuthContext);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = async () => {
        const mailTest = /\S+@\S+\.\S+/
        if (mailTest.test(email) === false) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Invalid Email Address' }
            });
            return;
        }
        if (!email || !password) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Email Address / Password required' }
            });
            return;
        }
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/account/login`,
            method: 'POST',
            data: {
                email,
                password
            },
            headers: {
                "Content-Type": "application/json"
            },
        }).then(res => {
            setLoading({
                type: 'LOADING', loading: false
            });
            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
            console.log(res.data.entity)
            dispatch({
                type: 'LOG_IN', user: res.data.entity
            });
        }).catch(err => {
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })

    }
    return (
        <React.Fragment>
            <Header2 />
            <div className="login-page-container">
                <Card className="login-form-container">
                    <div style={{ marginBottom: 10 }}>
                        <Title level={4} >Log In</Title>
                    </div>
                    <Form className="login-form">
                        <Form.Item>
                            <Input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Email"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button loading={loading} onClick={handleSubmit} type="primary" htmlType="submit" className="login-form-button">
                                Log in
                            </Button>
                        </Form.Item>
                        <Text>Forgot Password? Click <Link to="/forgot"><Text className="links">here</Text></Link></Text><br />
                        <Text>Need a working capital loan or your trade?  <Link to="/register"><Text className="links">Create account</Text></Link></Text>
                    </Form>
                </Card>
            </div>
            <div className="footer">
                <Text>&#169; 2019 Tradebuza Inc</Text>
            </div>
        </React.Fragment>
    )

}



export default Login;