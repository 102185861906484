import React, { useState } from 'react'
import { Typography, Row, Col, Timeline, Modal, Carousel } from 'antd'
import Layout from '../layout/Layout'
import Map from './OldMap'

const { Title } = Typography;



const renderColor = (status) => {
    if (status === 0) {
        return "gray"
    }
    else if (status === 2) {
        return "green"
    }
    else {
        return "orange"
    }
}

const renderStatus = (status) => {
    if (status === 0) {
        return "Not Done"
    }
    else if (status === 2) {
        return "Completed"
    }
    else {
        return "Ongoing"
    }
}




const Trade = (props) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [currentActivity, setCurrentActivity] = useState(null)


    const trade = props.location.state;

    console.log(trade)

    const viewImages = (activity) => {
        setCurrentActivity(activity)
        setModalVisible(true)
    }

    return (
        <Layout>
            <div>
                <Title level={4}>{trade.commodity.commodity}</Title>
                <br />
                <Row gutter={{ xs: 24, sm: 24, md: 6, lg: 6 }}>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <Map activities={trade.trade_activities} />
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>

                        <div className="window">
                            <h2 style={{ marginBottom: 20 }}>Volume: {trade.volume}MT</h2>
                            <Timeline>
                                {trade.trade_activities.sort((a, b) => b.status - a.status).map(activity => {
                                    return (
                                        <Timeline.Item key={activity._id} color={renderColor(activity.status)}>
                                            <h3>{activity.activity}</h3>
                                            <p>{renderStatus(activity.status)}</p>
                                            {activity.images && <p className="activity-images-button" onClick={() => viewImages(activity)}>View images ({activity.images.length})</p>}
                                        </Timeline.Item>
                                    )
                                })}
                            </Timeline>
                            {/* <Steps progressDot={customDot} direction="vertical" size="small" current={0}>
                                {trade.trade_activities.map(activity => {
                                    console.log(activity)
                                    return (
                                        <Step key={activity._id} title={activity.activity} />
                                    )
                                })}
                            </Steps> */}
                        </div>
                    </Col>
                </Row>
                <Modal
                    style={{ top: 20 }}
                    title="Tracked Images"
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    footer={null}
                >
                    <Carousel dotPosition="top">
                        {currentActivity && currentActivity.images.map(activity => {
                            return (
                                <div className="img-cont" key={activity._id}>
                                    <img alt="activity" className="responsive-img" src={activity.image} />
                                </div>
                            )
                        })}
                    </Carousel>

                </Modal>
            </div>
        </Layout>
    )
}



export default Trade;