import React, { useContext, useEffect, useState } from 'react'
import { Table, Typography, Modal, Button, Form, Input, Select, Tooltip, Icon, Popconfirm } from 'antd'
import axios from 'axios'
// import Layout from '../layout/layout'
import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'

const { Title, Text } = Typography;
const { Option } = Select;
const { Column } = Table;


const SourcingPartners = () => {

    const { user } = useContext(AuthContext);
    const { partners, dispatchPartners, loading, setLoading, setError } = useContext(DataContext);
    const [modalVisible, setModalVisible] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [name, setName] = useState("")
    const [commodities, setCommodities] = useState([]);
    const [commodity, setCommodity] = useState(0)
    const [mobile, setMobile] = useState("")
    const [address, setAddress] = useState("")
    const [banks, setBanks] = useState([])
    const [bank, setBank] = useState("")
    const [account_no, setAccount] = useState("")
    const [selectedId, setSelectedId] = useState(null)
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        setLoading({
            type: 'LOADING', loading: true
        });
        const fetchData = async () => {
            await axios.all([
                axios({
                    url: `${config.baseUrl}/srp`,
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${user.token}`
                    },
                }).catch((err) => {
                    setLoading({
                        type: 'LOADING', loading: false
                    });
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: err.message }
                    });
                    console.log(err)
                }),
                axios({
                    url: `${config.baseUrl}/commodities`,
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${user.token}`
                    },
                }).catch((err) => {
                    setLoading({
                        type: 'LOADING', loading: false
                    });
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: err.message }
                    });
                    console.log(err)
                }),
                axios({
                    url: `${config.baseUrl}/banks`,
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${user.token}`
                    },
                }).catch((err) => {
                    setLoading({
                        type: 'LOADING', loading: false
                    });
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: err.message }
                    });
                    console.log(err)
                }),
            ]).then(axios.spread((res1, res2, res3) => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                if (res1.data.status.code !== 100 || res2.data.status.code !== 100) {
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: res1.data.status.desc }
                    });
                    return;
                }
                dispatchPartners({
                    type: 'PARTNERS_FETCHED', partners: res1.data.entity.sourcing_partners
                })
                setCommodities(res2.data.entity.commodities)
                setBanks(res3.data.entity)

            })).catch(err => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: err.message }
                });
                console.log(err)
            });
        };
        fetchData();
    }, [dispatchPartners, setError, setLoading, user, refetch])

    const handleSubmit = async (editing) => {
        // if (!name || !mobile) {
        //     setError({
        //         type: 'SET_ERROR', error: { type: 'error', message: 'Commodity Name and Decription Required' }
        //     });
        //     return;
        // }
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: editing ? `${config.baseUrl}/srp/${selectedId}` : `${config.baseUrl}/srp`,
            method: editing ? 'PUT' : 'POST',
            data: {
                name,
                mobile,
                commodity,
                address,
                bank,
                account_no
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setModalVisible(false)
            setRefetch(true)
            setLoading({
                type: 'LOADING', loading: false
            });

            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
        }).catch(err => {
            setModalVisible(false)
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })

    }
    const handleCancel = () => {
        setModalVisible(false)
        setName("")
        setMobile("")
    }

    const deletePartner = async (id) => {
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/srp/${id}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setLoading({
                type: 'LOADING', loading: false
            });
            dispatchPartners({
                type: 'DELETE_PARTNER', partner_id: id
            })
        }).catch(err => {
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })
    }

    const viewSourcingPartner = (partner) => {
        setEditing(true)
        setSelectedId(partner._id)
        setName(partner.name)
        setBank(partner.bank)
        setMobile(partner.mobile)
        setAddress(partner.address)
        setAccount(partner.account_no)
        setCommodity(partner.commodity._id)
        setModalVisible(true)
    }
    return (
        // <Layout>
        <section>
            <div>
                <div className="flex-space-between">
                    <Title level={4}>Sourcing Partners</Title>
                    <Button type="primary" onClick={() => setModalVisible(true)}>Create Sourcing Partner</Button>
                </div>
                <Table
                    bordered
                    rowKey={record => record._id}
                    loading={loading}
                    style={{ borderRadius: 10, backgroundColor: 'white' }}
                    dataSource={partners}>
                    <Column
                        title="Name"
                        dataIndex="name"
                        key="name"
                    />
                    <Column
                        title="Commodity Name"
                        dataIndex="commodity.commodity"
                        key="commodity.commodity"
                    />
                    <Column
                        title="Address"
                        dataIndex="address"
                        key="address"
                    />
                    <Column
                        title="Actions"
                        key="actions"
                        render={(text, record) => (
                            <span>
                                <Tooltip title="View">
                                    <Button style={{ marginRight: 2 }} onClick={() => viewSourcingPartner(record)}>
                                        <Icon type="eye" />
                                    </Button>
                                </Tooltip>
                                <Popconfirm title="Are you sure you want to delete this sourcing partner?" onConfirm={() => deletePartner(text._id)} okText="Yes" cancelText="No">
                                    <Button type="danger" style={{ marginRight: 2 }}>
                                        <Icon type="delete" />
                                    </Button>
                                </Popconfirm>
                            </span>
                        )}
                    />
                </Table>
            </div>
            <div className="modal-container">
                <Modal
                    title="Create Sourcing Partner"
                    visible={modalVisible}
                    onOk={handleSubmit}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button loading={loading} type="primary" key="ok" onClick={() => handleSubmit(editing)}>
                            Ok
                        </Button>
                    ]}
                >
                    <Form.Item
                        style={{ margin: 0 }}
                    >
                        <Text> Name <Text style={{ color: 'red' }}>*</Text></Text>
                        <Input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ margin: 0 }}>
                        <Text> Commodity <Text style={{ color: 'red' }}>*</Text></Text><br />
                        <Select style={{ width: '50%' }} defaultValue={commodity ? commodity : "Select Commodity"} onChange={(e) => setCommodity(e)}>
                            {commodities.map((item) => {
                                return (
                                    <Option key={item._id} value={item._id}>{item.commodity}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        style={{ margin: 0 }}
                    >
                        <Text> Mobile <Text style={{ color: 'red' }}>*</Text></Text>
                        <Input
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            placeholder="Mobile"
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ margin: 0 }}>
                        <Text> Address <Text style={{ color: 'red' }}>*</Text></Text>
                        <Input
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="Address"
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ margin: 0 }}>
                        <Text> Bank <Text style={{ color: 'red' }}>*</Text></Text>
                        <Select defaultValue="Select Bank" value={bank} style={{ width: '100%' }} onChange={(value) => {
                            setBank(value)
                        }}>
                            <Option value="">Select Bank</Option>
                            {banks.map(bank => {
                                return (
                                    <Option key={bank.id} value={bank.name}>{bank.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{ margin: 0 }}>
                        <Text> Account Number <Text style={{ color: 'red' }}>*</Text></Text>
                        <Input
                            value={account_no}
                            onChange={(e) => setAccount(e.target.value)}
                            placeholder="Account Number"
                        />
                    </Form.Item>
                    <br />
                </Modal>
            </div>
        </section>
        // </Layout >
    )
}

export default SourcingPartners;