import React from 'react'
import { Tabs, Typography } from 'antd';
import Layout from '../layout/Layout'
import Lpo from './Lpo'
import Invoice from './Invoice'

const { TabPane } = Tabs;
const { Title } = Typography;

const Apply = () => {
    return (
        <Layout>
            <div>
                <Title level={3}>Loan Application</Title>
            </div>
            <br />
            <Tabs type="card">
                <TabPane tab="LPO Financing" key="1">
                    <Lpo />
                </TabPane>
                <TabPane tab="Invoice Discounting" key="2">
                    <Invoice />
                </TabPane>
            </Tabs>
        </Layout>
    );
}

export default Apply;