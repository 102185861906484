import React, { useContext, useEffect, useState } from 'react'
import { Table, Typography, Button, Tooltip, Icon, Form, Input, Modal, Popconfirm } from 'antd'
import axios from 'axios'

import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'

const { Title, Text } = Typography;
const { Column } = Table;

const Users = () => {

    const { user } = useContext(AuthContext);
    const [modalVisible, setModalVisible] = useState(false)
    const { users, dispatchUsers, loading, setLoading, setError } = useContext(DataContext);
    const [refetch, setRefetch] = useState(false);
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [password, setPassword] = useState("")
    const [editing, setEditing] = useState(false)
    const [selectedId, setSelectedId] = useState(null)


    useEffect(() => {
        const fetchData = async () => {
            setLoading({
                type: 'LOADING', loading: true
            });
            await axios({
                url: `${config.baseUrl}/staffs`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.token}`
                },
            }).then(res => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                if (res.data.status.code !== 100) {
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                    });
                    return;
                }
                dispatchUsers({
                    type: 'USERS_FETCHED', users: res.data.entity
                })
                console.log(res)

            }).catch((err) => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: err.message }
                });
            })
        };
        fetchData();
    }, [dispatchUsers, setError, setLoading, user, refetch])

    const handleCancel = () => {
        setModalVisible(false)
    }

    const handleSubmit = async (editing) => {
        if (!first_name) {
            return setError({
                type: 'SET_ERROR', error: { type: 'error', message: "First Name cannot be empty" }
            });
        }
        if (!last_name) {
            return setError({
                type: 'SET_ERROR', error: { type: 'error', message: "Last Name cannot be empty" }
            });
        }
        if (!editing && !email) {
            return setError({
                type: 'SET_ERROR', error: { type: 'error', message: "Email cannot be empty" }
            });
        }
        if (!mobile) {
            return setError({
                type: 'SET_ERROR', error: { type: 'error', message: "Mobile Number cannot be empty" }
            });
        }
        if (!editing && !password) {
            return setError({
                type: 'SET_ERROR', error: { type: 'error', message: "Password cannot be empty" }
            });
        }
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: editing ? `${config.baseUrl}/staff/${selectedId}` : `${config.baseUrl}/staff`,
            method: editing ? 'PUT' : 'POST',
            data: editing ? {
                first_name, last_name, mobile
            } : {
                    first_name,
                    last_name,
                    mobile,
                    email,
                    password,
                },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setModalVisible(false)
            setRefetch(true)
            setLoading({
                type: 'LOADING', loading: false
            });

            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
        }).catch(err => {
            setModalVisible(false)
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })
    }

    const viewFieldStaff = (staff) => {
        setEditing(true)
        setSelectedId(staff._id)
        setFirstName(staff.first_name)
        setLastName(staff.last_name)
        setMobile(staff.mobile)
        setModalVisible(true)
    }

    const addNewStaff = () => {
        setEditing(false)
        setFirstName("")
        setLastName("")
        setMobile("")
        setModalVisible(true)
    }

    const deleteFieldStaff = async (id) => {
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/staff/${id}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setLoading({
                type: 'LOADING', loading: false
            });
            dispatchUsers({
                type: 'DELETE_USER', user_id: id
            })
        }).catch(err => {
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })
    }

    return (
        <section>
            <div>
                <div className="flex-space-between">
                    <Title level={4}>Field Staff</Title>
                    <Button type="primary" onClick={() => {
                        addNewStaff()
                    }}>Create Field Staff</Button>
                </div>
                <Table
                    bordered
                    rowKey={record => record._id}
                    loading={loading}
                    style={{ borderRadius: 10, backgroundColor: 'white' }}
                    dataSource={users}>
                    <Column
                        title="First Name"
                        dataIndex="first_name"
                        key="first_name"
                    />
                    <Column
                        title="Last Name"
                        dataIndex="last_name"
                        key="last_name"
                    />
                    <Column
                        title="Email"
                        dataIndex="email"
                        key="email"
                    />
                    <Column
                        title="Mobile"
                        dataIndex="mobile"
                        key="mobile"
                    />
                    <Column
                        title="Actions"
                        key="actions"
                        render={(text, record) => (
                            <span>
                                <Tooltip title="View Field Staff">
                                    <Button type="default" style={{ marginRight: 2 }} onClick={() => viewFieldStaff(record)}>
                                        <Icon type="edit" />
                                    </Button>
                                </Tooltip>
                                <Popconfirm title="Are you sure you want to delete this field staff?" onConfirm={() => deleteFieldStaff(text._id)} okText="Yes" cancelText="No">
                                    <Button type="danger" style={{ marginRight: 2 }}>
                                        <Icon type="delete" />
                                    </Button>
                                </Popconfirm>
                            </span>
                        )}
                    />
                </Table>
                <div className="modal-container">
                    <Modal
                        title="Create Field Staff"
                        visible={modalVisible}
                        onOk={handleSubmit}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="back" onClick={handleCancel}>
                                Cancel
                                    </Button>,
                            <Button loading={loading} type="primary" key="ok" onClick={() => handleSubmit(editing)}>
                                Ok
                            </Button>
                        ]}
                    >
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> First Name <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First Name"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Last Name <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Email <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                disabled={editing}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Phone Number <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                placeholder="Phone Number"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}>
                            <Text> Password <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                disabled={editing}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                            />
                        </Form.Item>
                        <br />
                    </Modal>
                </div>
            </div>
        </section>
    )
}

export default Users;