export const partnersReducer = (state, action) => {
    switch (action.type) {
        case 'PARTNERS_FETCHED':
            return [...action.partners]
        case 'ADD_PARTNER':
            return [...state, action.partner]
        case 'DELETE_PARTNER':
            return state.filter(item => item._id !== action.partner_id)
        default:
            return state;
    }
}