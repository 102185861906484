import { notification } from 'antd';

const openNotificationWithIcon = (type, message) => {
    notification[type]({
        style: {
            marginTop: 50
        },
        message,
    });
};

export const errorReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ERROR':
            openNotificationWithIcon(action.error.type, action.error.message)
            return state;
        case 'SET_SUCCESS':
            openNotificationWithIcon(action.error.type, action.error.message)
            return state;
        default:
            return state;
    }
}