import React, { useContext, useEffect, useState } from 'react'
import { Table, Typography, Button, Tooltip, Icon, Modal, List, Tabs, InputNumber } from 'antd'
import axios from 'axios'
import moment from 'moment'
import Layout from '../layout/Layout'
import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'
import { formatCurrency } from '../../utils/formatCurrency'
import { renderStatusTag } from './utils'

const { Title, Text } = Typography;
const { Column } = Table;
const { TabPane } = Tabs;


const ManageLoans = () => {

    const { user } = useContext(AuthContext);
    const { loans, dispatchLoan, loading, setLoading, setError, setSuccess } = useContext(DataContext);
    const [modalVisible, setModalVisible] = useState(false)
    const [disburseModalVisible, setDisburseModal] = useState(false)
    const [currentLoan, setCurrentLoan] = useState({})
    const [amount, setAmount] = useState(0)
    const [activeTab, setActiveTab] = useState("1")

    useEffect(() => {
        const fetchData = async () => {
            setLoading({
                type: 'LOADING', loading: true
            });
            await axios({
                url: `${config.baseUrl}/loans`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.token}`
                },
            }).then(res => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                if (res.data.status.code !== 100) {
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                    });
                    return;
                }
                dispatchLoan({
                    type: 'LOANS_FETCHED', loans: res.data.entity.loans
                })
                console.log(res)

            }).catch((err) => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: err.message }
                });
            })
        };
        fetchData();
    }, [dispatchLoan, setError, setLoading, user])

    const displayContent = (data) => {
        setCurrentLoan(data)
        setModalVisible(true)
    }

    const setTab = (key) => {
        setActiveTab(key)
    }

    const displayDisburseModal = (data) => {
        setCurrentLoan(data)
        setDisburseModal(true)

        console.log(data)

    }
    const handleCancel = () => {
        setDisburseModal(false)
        setModalVisible(false)
    }

    const approveLoan = async () => {
        const { _id } = currentLoan;
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/loan/${_id}`,
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setLoading({
                type: 'LOADING', loading: false
            });
            handleCancel()
            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
            setSuccess({
                type: 'SET_SUCCESS', error: { type: 'success', message: res.data.status.desc }
            });
        }).catch(err => {
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })
    }

    const openBankStatement = (bs) => {
        window.open(bs.url, "_blank")
    }

    const openPurchaseOrder = (ps) => {
        window.open(ps.url, "_blank")
    }
    return (
        <Layout>
            <section>
                <div>
                    <div className="flex-space-between">
                        <Title level={4}>Loans</Title>
                    </div>
                    <Table
                        bordered
                        rowKey={record => record._id}
                        loading={loading}
                        style={{ borderRadius: 10, backgroundColor: 'white' }}
                        dataSource={loans}>
                        <Column
                            title="Application Date"
                            dataIndex="created"
                            key="created"
                            render={(text, record) => (
                                <span>
                                    {moment(text).format('DD/MM/YY')}
                                </span>
                            )}
                        />
                        <Column
                            title="Merchant"
                            dataIndex="user"
                            key="user"
                            render={(text, record) => (
                                <span>
                                    {`${text.first_name} ${text.last_name}`}
                                </span>
                            )}
                        />
                        <Column
                            title="Amount"
                            dataIndex="loan_amount"
                            key="loan_amount"
                            render={(text, record) => (
                                <span>
                                    {formatCurrency(text)}
                                </span>
                            )}
                        />
                        <Column
                            title="Tenure"
                            dataIndex="loan_tenure"
                            key="loan_tenure"
                        />
                        <Column
                            title="Commodity"
                            dataIndex="commodity.commodity"
                            key="commodity.commodity"
                            render={(text, record) => (
                                <span>
                                    {text ? text : `N/A`}
                                </span>
                            )}
                        />
                        <Column
                            title="Interest Rate"
                            dataIndex="loan_rate"
                            key="loan_rate"
                        />
                        <Column
                            title="Repayment Amount"
                            dataIndex="loan_repayment"
                            key="loan_repayment"
                            render={(text, record) => (
                                <span>
                                    {formatCurrency(text)}
                                </span>
                            )}
                        />
                        <Column
                            title="Status"
                            dataIndex="status"
                            key="status"
                            render={(text, record) => (
                                <span>
                                    {renderStatusTag(text)}
                                </span>
                            )}
                        />
                        <Column
                            title="Actions"
                            key="actions"
                            render={(text, record) => (
                                <span>

                                    <Tooltip title="View Loan">
                                        <Button type="default" onClick={() => displayContent(record)} style={{ marginRight: 2 }}>
                                            <Icon type="eye" />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Disburse Funds">
                                        <Button disabled={record.status !== 2} onClick={() => displayDisburseModal(record)} type="default" style={{ marginRight: 2 }}>
                                            <Icon type="wallet" />
                                        </Button>
                                    </Tooltip>
                                </span>
                            )}
                        />
                    </Table>
                    <div className="modal-container">
                        <Modal
                            style={{ top: 30 }}
                            title="Disburse Funds"
                            visible={disburseModalVisible}
                            // onOk={handleSubmit}
                            onCancel={handleCancel}
                            footer={[
                                <Button loading={loading} onClick={() => alert("To be Implemented")} type="primary" key="ok">
                                    Disburse
                                </Button>,
                                <Button key="back" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            ]}
                        >
                            <Tabs defaultActiveKey={activeTab} onChange={(key) => setTab(key)} type="card">
                                <TabPane tab="Merchant" key="1">
                                    <div>
                                        <div>
                                            <Text>Name</Text>
                                            <h3 style={{ fontWeight: 500 }}>
                                                {currentLoan.user ? `${currentLoan.user.first_name} ${currentLoan.user.last_name}` : ""}
                                            </h3>
                                        </div>
                                        <div>
                                            <Text>Account Number</Text>
                                            <h3 style={{ fontWeight: 500 }}>
                                                {currentLoan.user ? `${currentLoan.user.account_number}` : ""}
                                            </h3>
                                        </div>
                                        <div>
                                            <Text>Bank</Text>
                                            <h3 style={{ fontWeight: 500 }}>
                                                {currentLoan.user ? `${currentLoan.user.bank}` : ""}
                                            </h3>
                                        </div>
                                        <div>
                                            <Text>Amount to Disburse</Text><br />
                                            <h3 style={{ fontWeight: 500 }}>
                                                {formatCurrency(amount)}
                                            </h3>
                                            <InputNumber style={styles.input} onChange={(val) => setAmount(val)} />
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Sourcing Partner" key="2">
                                    <div>
                                        <div>
                                            <Text>Name</Text>
                                            <h3 style={{ fontWeight: 500 }}>
                                                {currentLoan.sourcing_partner ? `${currentLoan.sourcing_partner.name}` : ""}
                                            </h3>
                                        </div>
                                        <div>
                                            <Text>Account Number</Text>
                                            <h3 style={{ fontWeight: 500 }}>
                                                {currentLoan.sourcing_partner ? `${currentLoan.sourcing_partner.account_no}` : ""}
                                            </h3>
                                        </div>
                                        <div>
                                            <Text>Bank</Text>
                                            <h3 style={{ fontWeight: 500 }}>
                                                {currentLoan.sourcing_partner ? `${currentLoan.sourcing_partner.bank}` : ""}
                                            </h3>
                                        </div>
                                        <div>
                                            <Text>Amount to Disburse</Text><br />
                                            <h3 style={{ fontWeight: 500 }}>
                                                {formatCurrency(amount)}
                                            </h3>
                                            <InputNumber style={styles.input} onChange={(val) => setAmount(val)} />
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </Modal>
                    </div>
                    <div className="modal-container">
                        <Modal
                            style={{ top: 30 }}
                            title={currentLoan.user ? <div>
                                <h3>{currentLoan.user.first_name} {currentLoan.user.last_name}</h3>
                                <h4>Applied: {moment(currentLoan.created).format('DD/MM/YY')}</h4>
                            </div> : ''}
                            visible={modalVisible}
                            // onOk={handleSubmit}
                            onCancel={handleCancel}
                            footer={currentLoan.status === 0 && [
                                <Button loading={loading} onClick={() => approveLoan()} type="primary" key="ok">
                                    Approve
                                </Button>,
                                <Button key="back" onClick={handleCancel}>
                                    Decline
                                </Button>
                            ]}
                        >
                            <List.Item
                                actions={[<h4>{currentLoan.type === 0 ? `LPO Financing` : `Invoice Discounting`}</h4>]}
                            >

                                <List.Item.Meta
                                    title={<span>Type</span>}
                                />
                            </List.Item>
                            <List.Item
                                actions={[<h4>{formatCurrency(currentLoan.loan_amount)}</h4>]}
                            >

                                <List.Item.Meta
                                    title={<span>Loan Amount</span>}
                                />
                            </List.Item>
                            <List.Item
                                actions={[<h4>{currentLoan.loan_rate}%</h4>]}
                            >

                                <List.Item.Meta
                                    title={<span>Interest Rate</span>}
                                />
                            </List.Item>
                            <List.Item
                                actions={[<h4>{currentLoan.commodity ? currentLoan.commodity.commodity : "N/A"}</h4>]}
                            >

                                <List.Item.Meta
                                    title={<span>Commodity</span>}
                                />
                            </List.Item>
                            <List.Item
                                actions={[<h4>{currentLoan.sourcing_partner ? currentLoan.sourcing_partner.name : 'N/A'}</h4>]}
                            >

                                <List.Item.Meta
                                    title={<span>Sourcing Partner</span>}
                                />
                            </List.Item>
                            <List.Item
                                actions={
                                    [<h4>{currentLoan.bank_statement ?
                                        <Button onClick={() => openBankStatement(currentLoan.bank_statement)} type="link">
                                            View
                                        </Button>
                                        : 'N/A'}</h4>
                                    ]}
                            >

                                <List.Item.Meta
                                    title={<span>Bank Statement</span>}
                                />
                            </List.Item>
                            <List.Item
                                actions={
                                    [
                                        <h4>{currentLoan.purchase_order ?
                                            <Button
                                                onClick={() => openPurchaseOrder(currentLoan.purchase_order)}
                                                type="link">View
                                            </Button> :
                                            'N/A'}
                                        </h4>
                                    ]
                                }
                            >

                                <List.Item.Meta
                                    title={<span>Purchase Order</span>}
                                />
                            </List.Item>
                            <List.Item
                                actions={
                                    [
                                        <h4>{currentLoan.purchase_order ?
                                            <Button
                                                onClick={() => openPurchaseOrder(currentLoan.purchase_order)}
                                                type="link">View
                                            </Button> :
                                            'N/A'}
                                        </h4>
                                    ]
                                }
                            >

                                <List.Item.Meta
                                    title={<span>Purchase Order</span>}
                                />
                            </List.Item>
                            <List.Item
                                actions={[<h4>{formatCurrency(currentLoan.loan_repayment)}</h4>]}
                            >

                                <List.Item.Meta
                                    title={<span>Repayment Amount</span>}
                                />
                            </List.Item>
                            <List.Item
                                actions={[<h4>{currentLoan.loan_tenure} Month(s)</h4>]}
                            >

                                <List.Item.Meta
                                    title={<span>Loan Tenure</span>}
                                />
                            </List.Item>
                            <List.Item
                                actions={[renderStatusTag(currentLoan.status)]}
                            >

                                <List.Item.Meta
                                    title={<span>Status</span>}
                                />
                            </List.Item>
                        </Modal>
                    </div>
                </div>
            </section>
        </Layout >
    )
}

const styles = {
    input: {
        marginTop: 10,
        width: '30%'
    }
}

export default ManageLoans