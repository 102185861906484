import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { Menu, Dropdown, Typography, Button } from 'antd'
import { AuthContext } from '../../contexts/AuthContext'


const { Text } = Typography;


function Header2(props) {
    const { user, dispatch } = useContext(AuthContext)

    const onClick = ({ key }) => {
        if (key === "0") {
            props.history.push('/settings')
        }
        if (key === "1") {
            dispatch({
                type: 'LOG_OUT', user: {}
            });
        }
    }

    const menu = user.user ? (
        <Menu onClick={onClick}>
            <Menu.Item key="0">
                <Text>Profile</Text>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="1">Log Out</Menu.Item>
        </Menu>
    ) : null
    return (
        <React.Fragment>
            {user.isAuthenticated === true ?
                <div className="header">
                    <div>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Button size={'large'} style={{ justifyContent: 'center' }} type="link">
                                {user.user.first_name} {user.user.last_name} | {user.user.role}
                            </Button>
                            {/* <Avatar style={{ backgroundColor: "#009663", verticalAlign: 'middle' }} size="large">
                                {user.user.first_name[0]}
                            </Avatar> */}
                        </Dropdown>

                    </div>
                </div>
                :
                <div className="header2">
                    <div>
                        <img src="https://test.tradebuza.com/build/images/t_logo.png" alt="session-logo" className="img-fluid" width="165" height="52.5" />
                    </div>
                </div>}


        </React.Fragment>
    );
}

export default withRouter(Header2);