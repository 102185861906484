import React, { useContext, useState } from 'react'
import axios from 'axios'
import { Row, Col, Avatar, Form, Typography, Input, Button, Card, Icon } from 'antd'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'
import config from '../../config/index'

const { Text, Title } = Typography;

const Profile = () => {
    const { user, dispatch } = useContext(AuthContext);
    const { loading, setLoading, setError } = useContext(DataContext);
    const [first_name, setFirstName] = useState(user.user.first_name)
    const [last_name, setLastName] = useState(user.user.last_name)
    const [mobile, setMobile] = useState(user.user.mobile)

    const handleSubmit = async () => {
        if (!first_name || !last_name) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'First Name and Last Name cannot be empty' }
            });
            return;
        }
        if (!mobile) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Mobile Number cannot be empty' }
            });
            return;
        }
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/account`,
            method: 'PUT',
            data: {
                first_name,
                last_name,
                mobile
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then((res) => {
            setLoading({
                type: 'LOADING', loading: false
            });

            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
            dispatch({
                type: 'SET_USER', user: res.data.entity
            });
        }).catch(err => {
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })
    }

    return (
        <div >
            <Card>
                <Row style={{ marginTop: 20 }} gutter={{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        {/* <Avatar style={{ backgroundColor: '#009663' }} size={64}>
                        {user.user.first_name[0]}
                    </Avatar>
                    <div style={{ marginTop: 10 }}>
                        <Title level={4}>{user.user.first_name} {user.user.last_name}</Title>
                        <Text>{user.user.email}</Text><br />
                        <Text>{user.user.role.toUpperCase()}</Text>
                    </div> */}
                        <Card bordered={false}>

                            <Avatar style={{ backgroundColor: '#009663' }} size={72}>{user.user.first_name[0]}</Avatar>
                            <Title style={{ marginTop: 20 }} level={4}>
                                {user.user.first_name} {user.user.last_name}
                            </Title>
                            <Text style={{ marginTop: 20 }} level={4}>
                                {user.user.role.toUpperCase()}
                            </Text><br />
                            <Text style={{ marginTop: 20 }} level={4}>
                                <Icon type="phone" style={{ marginRight: 10 }} />
                                {user.user.mobile}
                            </Text><br />
                            <Text style={{ marginTop: 20 }} level={4}>
                                <Icon type="mail" style={{ marginRight: 10 }} />
                                {user.user.email}
                            </Text><br />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Title level={4}>Edit Profile</Title>
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> First Name</Text>
                            <Input
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First Name"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Last Name</Text>
                            <Input
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Mobile Number</Text>
                            <Input
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                placeholder="Mobile"
                            />
                        </Form.Item>
                        <br />
                        <Button onClick={handleSubmit} loading={loading} type="primary">Submit</Button>

                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default Profile