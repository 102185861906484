import React, { createContext, useReducer, useEffect } from 'react'
import { authReducer } from '../reducers/AuthReducer';
import { loadingReducer } from '../reducers/LoadingReducer';
import { errorReducer } from '../reducers/ErrorReducer';


export const AuthContext = createContext();

const AuthContextProvider = (props) => {
    const [user, dispatch] = useReducer(authReducer, {}, () => {
      const localData = localStorage.getItem('userData')
      return localData ? JSON.parse(localData) : {}
    });

    const [loading, setLoading] = useReducer(loadingReducer, false);
    const [error, setError] = useReducer(errorReducer, {});

    useEffect(() => {
        localStorage.setItem('userData', JSON.stringify(user))
    }, [user])
    return (
        <AuthContext.Provider value={{user, dispatch, loading, setLoading, error, setError}}>
            {props.children}
        </AuthContext.Provider>
    )
}
 
export default AuthContextProvider;