import React, { useContext, useState } from 'react'
import axios from 'axios'
import { Row, Col, Form, Typography, Input, Button, Icon, Card, message, Upload } from 'antd'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'
import config from '../../config/index'

const { Text, Title } = Typography;

const { TextArea } = Input;

const Business = () => {
    const { user, dispatch } = useContext(AuthContext);
    const { loading, setLoading, setError } = useContext(DataContext);
    const [account_number, setAccountNumber] = useState(user.user.account_number)
    const [bank, setBank] = useState(user.user.bank)
    const [uploading, setUploading] = useState(false)
    const [business_address, setBusinessAddress] = useState(user.user.business_address)
    // const [company_name, setCompanyName] = useState(user.user.company_name)
    const [business_name, setBusinessName] = useState(user.user.business_name)
    const [rc_number, setRCNumber] = useState(user.user.rc_number)
    const [logo, setLogo] = useState("https://images.fastcompany.net/image/upload/w_596,c_limit,q_auto:best,f_auto/fc/3034007-inline-i-applelogo.jpg")
    // const [state, setState] = useState(user.user.state)
    // const [lga, setLGA] = useState(user.user.lga)
    // const [country, setRCNsetCountryumber] = useState(user.user.country)

    console.log(user.user)
    const handleSubmit = async () => {
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/account`,
            method: 'PUT',
            data: {
                account_number,
                rc_number,
                bank,
                // company_name,
                business_address,
                business_name,
                logo
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then((res) => {
            console.log(res)
            setLoading({
                type: 'LOADING', loading: false
            });

            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
            dispatch({
                type: 'SET_USER', user: res.data.entity
            });
        }).catch(err => {
            console.log(err)
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })
    }

    // const getBase64 = (img, callback) => {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => callback(reader.result));
    //     reader.readAsDataURL(img);
    // }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    // const handleChange = info => {
    //     if (info.file.status === 'uploading') {
    //         setUploading(true)
    //         return;
    //     }
    //     if (info.file.status === 'done') {
    //         // Get this url from response in real world.
    //         getBase64(info.file.originFileObj, logo => {
    //             setLogo(logo)
    //             setUploading(false)
    //         }
    //         );
    //     }
    // };



    const uploadLogo = {
        name: 'file',
        action: `${config.baseUrl}/file`,
        headers: {
            "Authorization": `Bearer ${user.token}`
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {

            }
            if (info.file.status === 'done') {
                console.log(info.file.response)
                setUploading(false)
                setLogo(info.file.response.entity.url)
                console.log(logo)
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                setUploading(false)
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };



    const uploadButton = (
        <div>
            <Icon type={uploading ? 'loading' : 'plus'} />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <div >
            <Card>

                <Row style={{ marginTop: 20 }} gutter={{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        <Card bordered={false}>
                            <Upload
                                {...uploadLogo}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                            >
                                {logo ? <img src={logo} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                            <Title style={{ marginTop: 20 }} level={4}>
                                {user.user.company_name}
                            </Title>
                            <Text style={{ marginTop: 20 }} level={4}>
                                RC: {user.user.rc_number}
                            </Text><br />
                            <Text style={{ marginTop: 20 }} level={4}>
                                <Icon type="home" style={{ marginRight: 10 }} />
                                {user.user.business_address}
                            </Text><br />
                            <Text style={{ marginTop: 20 }} level={4}>
                                <Icon type="bank" style={{ marginRight: 10 }} />
                                {user.user.bank}
                            </Text><br />
                            <Text style={{ marginTop: 20 }} level={4}>
                                Account Number: {user.user.account_number}
                            </Text><br />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Title level={4}>Edit Business Profile</Title>
                        {/* <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Company Name</Text>
                            <Input
                                value={company_name}
                                onChange={(e) => setCompanyName(e.target.value)}
                                placeholder="Company Name"
                            />
                        </Form.Item> */}
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Business Name</Text>
                            <Input
                                value={business_name}
                                onChange={(e) => setBusinessName(e.target.value)}
                                placeholder="Business Name"
                            />
                        </Form.Item>


                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Business Address</Text>
                            <TextArea
                                value={business_address}
                                onChange={(e) => setBusinessAddress(e.target.value)}
                                placeholder="Business Address"
                                rows={4} />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> RC Number</Text>
                            <Input
                                value={rc_number}
                                onChange={(e) => setRCNumber(e.target.value)}
                                placeholder="RC Number"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Account Number</Text>
                            <Input
                                value={account_number}
                                onChange={(e) => setAccountNumber(e.target.value)}
                                placeholder="Account Number"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Bank</Text>
                            <Input
                                value={bank}
                                onChange={(e) => setBank(e.target.value)}
                                placeholder="Bank"
                            />
                        </Form.Item>
                        <br />
                        <Button onClick={handleSubmit} loading={loading} type="primary">Submit</Button>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default Business