import React, { useContext } from 'react';
import { AuthContext } from './contexts/AuthContext';
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { AuthRoute, UnauthRoute } from 'react-router-auth'
import Dashboard from './components/dashboard/Dashboard'
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Apply from './components/loan/Apply';
import Loans from './components/loan/Loans';
import ManageLoans from './components/loan/ManageLoans';
import Trade from './components/trade/Trade';
import Trades from './components/trade/Trades';
import Main from './components/main/Main';
import Settings from './components/settings/Settings';
import NewInvoice from './components/invoicing/NewInvoice';
import TradeActivities from './components/loan/TradeActivities';
import SetTradeActivities from './components/loan/SetTradeActivities';

import './App.css';
import ReviewInvoice from './components/invoicing/ReviewInvoice';
import Payments from './components/payment/Payments';
// import ViewDoc from './components/loan/ViewDoc';


function App() {
  const { user } = useContext(AuthContext);
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Main} redirectTo="/dashboard" authenticated={user.isAuthenticated} />
          <UnauthRoute exact path="/login" component={Login} redirectTo="/dashboard" authenticated={user.isAuthenticated} />
          <Route exact path="/register" component={Register} />
          <AuthRoute exact path="/dashboard" component={Dashboard} redirectTo="/login" authenticated={user.isAuthenticated} />
          <AuthRoute exact path="/loans" component={Loans} redirectTo="/login" authenticated={user.isAuthenticated} />
          <AuthRoute exact path="/loans/apply" component={Apply} redirectTo="/login" authenticated={user.isAuthenticated} />
          <AuthRoute exact path="/loans/all" component={ManageLoans} redirectTo="/login" authenticated={user.isAuthenticated} />
          <AuthRoute exact path="/loans/set-trade-activities" component={SetTradeActivities} redirectTo="/login" authenticated={user.isAuthenticated} />
          <AuthRoute exact path="/trades" component={Trades} redirectTo="/login" authenticated={user.isAuthenticated} />
          <AuthRoute exact path="/trades/:id" component={Trade} redirectTo="/login" authenticated={user.isAuthenticated} />
          <AuthRoute exact path="/settings" component={Settings} redirectTo="/login" authenticated={user.isAuthenticated} />
          <AuthRoute exact path="/trade-activities" component={TradeActivities} redirectTo="/login" authenticated={user.isAuthenticated} />
          <AuthRoute exact path="/invoicing/new" component={NewInvoice} redirectTo="/login" authenticated={user.isAuthenticated} />
          <AuthRoute exact path="/payments" component={Payments} redirectTo="/login" authenticated={user.isAuthenticated} />
          {/* <AuthRoute exact path="/loans/doc" component={ViewDoc} redirectTo="/login" authenticated={user.isAuthenticated} /> */}
          <Route exact path="/invoicing/review" component={ReviewInvoice} />
        </Switch>
      </Router>
    </div>
  );
}


export default App;

