import React, { useContext, useState, useEffect } from 'react'
import { Typography, Button, Card, Row, Col, Form, Select, InputNumber, Icon, Alert } from 'antd'
import axios from 'axios'

import Layout from '../layout/Layout'
import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext'
import { formatCurrency } from '../../utils/formatCurrency'
import { DataContext } from '../../contexts/DataContext'

const { Title, Text } = Typography;
const { Option } = Select;

const SetTradeActivities = (props) => {
    const { activities, dispatchActivities, loading, setLoading, setError } = useContext(DataContext);
    const [refetch] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [loanAmount] = useState(props.location.state.loan_amount)
    const [changingAmount, setChangingAmount] = useState(props.location.state.loan_amount)
    const [currentActivity, setCurrentActivity] = useState("");
    const [currentAmount, setCurrentAmount] = useState(0)
    const [selectedActivities, setSelectedActivities] = useState([])

    const { user } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            await axios.all([
                axios({
                    url: `${config.baseUrl}/activities`,
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${user.token}`
                    },
                }).catch((err) => {
                    setLoading({
                        type: 'LOADING', loading: false
                    });
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: err.message }
                    });
                    console.log(err)
                }),
                axios({
                    url: `${config.baseUrl}/staffs`,
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${user.token}`
                    },
                }).catch((err) => {
                    setLoading({
                        type: 'LOADING', loading: false
                    });
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: err.message }
                    });
                    console.log(err)
                }),
            ]).then(axios.spread((res1, res2) => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                if (res1.data.status.code !== 100 || res2.data.status.code !== 100) {
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: res1.data.status.desc }
                    });
                    return;
                }
                dispatchActivities({
                    type: 'ACTIVITIES_FETCHED', activities: res1.data.entity.activities
                })
                if (!res2.data.entity.length) {
                    setDisabled(true)
                }

            })).catch(err => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: err.message }
                });
                console.log(err)
            });
        };
        fetchData();
    }, [dispatchActivities, setError, setLoading, user, refetch])

    useEffect(() => {
        if (!selectedActivities.length) {
            setChangingAmount(loanAmount)
            return;
        }
        if (selectedActivities.length === 1) {
            const sum = selectedActivities[0].amount
            setChangingAmount(loanAmount - sum)
            return;
        }
        if (selectedActivities.length > 1) {
            let sum = selectedActivities.reduce(function (prev, cur) {
                return prev + cur.amount;
            }, 0);
            setChangingAmount(loanAmount - sum)
            return;
        }

    }, [selectedActivities, loanAmount]);

    const addActivity = () => {
        if (!currentActivity) {
            return setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Please Select Activity' }
            });
        }
        if (!currentAmount) {
            return setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Please Select Amount' }
            });
        }
        const data = {
            activity: currentActivity,
            amount: currentAmount
        };

        if (currentAmount > loanAmount) {
            return setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Loan Amount Exceeded' }
            });
        }

        setSelectedActivities([...selectedActivities, data])
        setCurrentAmount(0)
        setCurrentActivity("")
    }

    const removeItem = (item) => {
        setSelectedActivities(selectedActivities.filter(activity => activity.activity !== item.activity))
    }

    const handleSubmit = async () => {
        if (!selectedActivities.length) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: "Please select trade activities for this loan" }
            });
            return;
        }
        if (selectedActivities.length === 1) {
            let sum = selectedActivities[0].amount
            if (loanAmount < sum) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: "Loan Amount Exceeded" }
                });
                return;
            }
        }
        let sum = selectedActivities.reduce(function (prev, cur) {
            return prev + cur.amount;
        }, 0);
        if (loanAmount < sum) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: "Loan Amount Exceeded" }
            });
            return;
        }
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/trade/start`,
            method: 'POST',
            data: {
                loan_id: props.location.state._id,
                activities: selectedActivities,
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then((res) => {
            setLoading({
                type: 'LOADING', loading: false
            });
            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
            setError({
                type: 'SET_SUCCESS', error: { type: 'success', message: res.data.status.desc }
            })
            props.history.push('/trades')
        }).catch((err) => {
            console.log(err)
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })
    }

    const filteredActivities = activities.filter(item => selectedActivities.map(act => act.activity).indexOf(item.activity) === -1)

    return (
        <Layout>
            <div>
                {disabled &&
                    <Alert
                        message="You need to create field agents before setting your trade activities. Go to the Field Staff tab in settings to do that." type="info" showIcon />
                }
                <br />
                <Title style={{ fontWeight: 'bold' }} level={4}>Select Trade Activities</Title>
                <br />
                <Card>
                    <div style={{ textAlign: 'center' }}>
                        <h3 style={{ fontWeight: 'bold' }}>Trade Activities</h3>
                        <h4>Kindly select different activities for this trade and the amount required at each stage</h4>
                    </div>
                    <br />
                    <div>
                        <h2 style={{ fontWeight: 'bold' }}>Total Approved Loan Amount: {formatCurrency(changingAmount)}</h2>
                    </div>
                    <Card>
                        <div className="trade-activities">
                            <Row gutter={{ xs: 24, sm: 24, md: 12, lg: 12 }} className="trade-activities">
                                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        colon={false}
                                        label={<Text>Trade Activity <Text style={{ color: 'red' }}>*</Text></Text>}>
                                        <Select defaultValue="Select Activity" value={currentActivity} style={{ width: '100%' }} onChange={(value) => {
                                            setCurrentActivity(value)
                                        }}>

                                            <Option value="">Select Activity</Option>

                                            {/* {console.log(activities.filter(item => selectedActivities.map(x => x.activity !== item.activity)))} */}
                                            {filteredActivities.map(activity => {
                                                return (
                                                    <Option key={activity.activity} value={activity.activity}>{activity.activity}</Option>
                                                )
                                            })}

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        colon={false}
                                        label={<Text>Amount Required <Text style={{ color: 'red' }}>*</Text></Text>}>
                                        <InputNumber style={{ width: '100%' }} value={currentAmount} onChange={(e) => setCurrentAmount(e)} type="number" placeholder="Amount" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        colon={false}
                                        label={<Text style={{ color: 'white' }}>Blank</Text>}>
                                        <Button onClick={() => addActivity()} type="default" icon="plus">
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                    <br />
                    <h3 style={{ fontWeight: 'bold' }}>Selected Activities</h3>
                    <Card>

                        <div>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <h3 style={{ fontWeight: 'bold' }}>Trade Activities</h3>

                                </Col>
                                <Col span={12}>
                                    <h3 style={{ fontWeight: 'bold' }}>Amount Required</h3>
                                </Col>
                            </Row>
                            <br />
                            {selectedActivities.map(item => {
                                return (
                                    <Row key={item.activity} gutter={24} style={{ marginTop: 10 }}>
                                        <Col span={10}>
                                            <h4>{item.activity}</h4>

                                        </Col>
                                        <Col span={10}>
                                            <h4>{formatCurrency(parseInt(item.amount))}</h4>
                                        </Col>
                                        <Col span={4}>
                                            <Button type="danger" style={{ marginRight: 2 }} onClick={() => removeItem(item)}>
                                                <Icon type="delete" />
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                    </Card>
                    <br />
                    <Button disabled={disabled} type="primary" onClick={handleSubmit} loading={loading}>Submit</Button>
                </Card>
            </div>
        </Layout>

    )
}

export default SetTradeActivities;