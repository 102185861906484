import React, { createContext, useReducer } from 'react'
import { commoditiesReducer } from '../reducers/CommoditiesReducer';
import { activitiesReducer } from '../reducers/ActivitiesReducer';
import { tradeReducer } from '../reducers/TradeReducer';
import { errorReducer } from '../reducers/ErrorReducer';
import { loadingReducer } from '../reducers/LoadingReducer';
import { partnersReducer } from '../reducers/SourcingPartnersReducer';
import { merchantsReducer } from '../reducers/MerchantsReducer';
import { loanReducer } from '../reducers/LoanReducer';
import { userReducer } from '../reducers/UserReducer';


export const DataContext = createContext();

const DataContextProvider = (props) => {
    const [commodities, dispatchCommodities] = useReducer(commoditiesReducer, []);
    const [activities, dispatchActivities] = useReducer(activitiesReducer, []);
    const [trades, dispatchTrades] = useReducer(tradeReducer, []);
    const [partners, dispatchPartners] = useReducer(partnersReducer, []);
    const [merchants, dispatchMerchants] = useReducer(merchantsReducer, []);
    const [loans, dispatchLoan] = useReducer(loanReducer, []);
    const [users, dispatchUsers] = useReducer(userReducer, []);
    const [loading, setLoading] = useReducer(loadingReducer, false);
    const [error, setError] = useReducer(errorReducer, {});
    const [success, setSuccess] = useReducer(errorReducer, {});

    return (
        <DataContext.Provider value={
            {
                trades,
                dispatchTrades,
                activities,
                dispatchActivities,
                commodities,
                dispatchCommodities,
                partners,
                dispatchPartners,
                merchants,
                dispatchMerchants,
                loans,
                dispatchLoan,
                users,
                dispatchUsers,
                error,
                setError,
                success,
                setSuccess,
                loading,
                setLoading
            }
        }>
            {props.children}
        </DataContext.Provider>
    )
}

export default DataContextProvider;