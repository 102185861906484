export const tradeReducer = (state, action) => {
    switch (action.type) {
        case 'TRADES_FETCHED':
            return [...action.trades]
        case 'ONGOING':
            return [...action.trades].filter(item => item.trade_status === 1)
        case 'COMPLETED':
            return [...action.trades].filter(item => item.trade_status === 2)
        case 'ALL':
            return state
        default:
            return state;
    }
}