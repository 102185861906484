import React, { useContext, useEffect, useState } from 'react'
import { Table, Typography, Button, Tooltip, Icon, Modal, List, Alert } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'

import Layout from '../layout/Layout'
import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'
import { formatCurrency } from '../../utils/formatCurrency'
import { renderStatusTag } from './utils'

const { Title } = Typography;
const { Column } = Table;

const Loans = (props) => {
    const { user } = useContext(AuthContext);
    const { loans, dispatchLoan, loading, setLoading, setError } = useContext(DataContext);
    const [modalVisible, setModalVisible] = useState(false)
    const [currentLoan, setCurrentLoan] = useState({})
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            setLoading({
                type: 'LOADING', loading: true
            });
            await axios({
                url: `${config.baseUrl}/loans/user`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.token}`
                },
            }).then(res => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                if (res.data.status.code !== 100) {
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                    });
                    return;
                }
                dispatchLoan({
                    type: 'LOANS_FETCHED', loans: res.data.entity.loans
                })
                checkLoan(res.data.entity.loans)

            }).catch((err) => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: err.message }
                });
            })
        };
        fetchData();
    }, [dispatchLoan, setError, setLoading, user])

    const displayContent = async (data) => {
        setModalVisible(false)
        setCurrentLoan(data)
        setModalVisible(true)
    }


    const handleCancel = () => {
        setModalVisible(false)
    }

    const checkLoan = (loans) => {
        const approved = loans.filter(loan => loan.status === 1)
        if (approved.length) {
            setAlert(true)
        }
    }

    return (
        <Layout>
            <section>
                {alert &&
                    <Alert
                        message="You have approved loans that are yet to be accepted" type="info" showIcon />
                }

                <br />
                <Title level={4}>Loans</Title>
                <br />
                <Table
                    style={{ borderRadius: 10, backgroundColor: 'white' }}
                    bordered
                    rowKey={record => record._id}
                    loading={loading}
                    dataSource={loans}>
                    <Column
                        title="Date"
                        dataIndex="created"
                        key="created"
                        render={(text, record) => (
                            <span>
                                {moment(text).format('DD/MM/YY')}
                            </span>
                        )}
                    />
                    <Column
                        title="Amount"
                        dataIndex="loan_amount"
                        key="loan_amount"
                        render={(text, record) => (
                            <span>
                                {formatCurrency(text)}
                            </span>
                        )}
                    />
                    <Column
                        title="Loan Type"
                        dataIndex="type"
                        key="type"
                        render={(text, record) => (
                            <span>
                                {text === "INVOICE" ? `Invoice Discounting` : `LPO Financing`}
                            </span>
                        )}
                    />
                    <Column
                        title="Tenure"
                        dataIndex="loan_tenure"
                        key="loan_tenure"
                    />
                    <Column
                        title="Commodity"
                        dataIndex="commodity.commodity"
                        key="commodity.commodity"
                        render={(text, record) => (
                            <span>
                                {text ? text : `N/A`}
                            </span>
                        )}
                    />
                    <Column
                        title="Interest Rate"
                        dataIndex="loan_rate"
                        key="loan_rate"
                    />
                    <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        render={(text, record) => (
                            <span>
                                {renderStatusTag(text)}
                            </span>
                        )}
                    />
                    <Column
                        title="Repayment Amount"
                        dataIndex="loan_repayment"
                        key="loan_repayment"
                        render={(text, record) => (
                            <span>
                                {formatCurrency(text)}
                            </span>
                        )}
                    />
                    <Column
                        title="Actions"
                        key="actions"
                        render={(text, record) => (
                            <span>
                                <Tooltip title="View Loan">
                                    <Button type="default" style={{ marginRight: 2 }} onClick={() => displayContent(record)}>
                                        <Icon type="eye" />
                                    </Button>
                                </Tooltip>
                                {/* {text.status === 1 &&
                                    <Tooltip title="Accept">
                                        <Link
                                            to={{
                                                pathname: '/loans/set-trade-activities',
                                                state: record
                                            }}
                                        >
                                            <Button type="default" style={{ marginRight: 2 }}>
                                                <Icon type="check" />
                                            </Button>
                                        </Link>
                                    </Tooltip>
                                } */}
                            </span>
                        )}
                    />
                </Table>
                <div className="modal-container">
                    <Modal
                        style={{ top: 20 }}
                        title={currentLoan.user ? <div>
                            <h3>{currentLoan.user.first_name} {currentLoan.user.last_name}</h3>
                            <h4>Applied: {moment(currentLoan.created).format('DD/MM/YY')}</h4>
                            <br />
                            <h2>{formatCurrency(currentLoan.loan_amount)}</h2>
                        </div> : ''}
                        visible={modalVisible}
                        // onOk={handleSubmit}
                        onCancel={handleCancel}
                        footer={null}
                    >
                        <List.Item
                            actions={[<h4>{currentLoan.type === 0 ? `LPO Financing` : `Invoice Discounting`}</h4>]}
                        >

                            <List.Item.Meta
                                title={<span>Type</span>}
                            />
                        </List.Item>
                        <List.Item
                            actions={[<h4>{formatCurrency(currentLoan.loan_amount)}</h4>]}
                        >

                            <List.Item.Meta
                                title={<span>Loan Amount</span>}
                            />
                        </List.Item>
                        <List.Item
                            actions={[<h4>{currentLoan.loan_rate}%</h4>]}
                        >

                            <List.Item.Meta
                                title={<span>Interest Rate</span>}
                            />
                        </List.Item>
                        <List.Item
                            actions={[<h4>{currentLoan.commodity ? currentLoan.commodity.commodity : "N/A"}</h4>]}
                        >

                            <List.Item.Meta
                                title={<span>Commodity</span>}
                            />
                        </List.Item>
                        <List.Item
                            actions={[<h4>{currentLoan.sourcing_partner ? currentLoan.sourcing_partner.name : 'N/A'}</h4>]}
                        >

                            <List.Item.Meta
                                title={<span>Sourcing Partner</span>}
                            />
                        </List.Item>
                        <List.Item
                            actions={[<h4>{formatCurrency(currentLoan.loan_repayment)}</h4>]}
                        >

                            <List.Item.Meta
                                title={<span>Repayment Amount</span>}
                            />
                        </List.Item>
                        <List.Item
                            actions={[<h4>{currentLoan.loan_tenure} Month(s)</h4>]}
                        >

                            <List.Item.Meta
                                title={<span>Loan Tenure</span>}
                            />
                        </List.Item>
                        <List.Item
                            actions={[renderStatusTag(currentLoan.status)]}
                        >

                            <List.Item.Meta
                                title={<span>Status</span>}
                            />
                        </List.Item>
                        {currentLoan.status === 1 &&
                            <Link
                                to={{
                                    pathname: '/loans/set-trade-activities',
                                    state: currentLoan
                                }}
                            >
                                <Button type="primary">Accept Loan Offer</Button>
                            </Link>
                        }

                    </Modal>
                </div>

            </section>
        </Layout >
    );
}

// class Loans extends React.Component {
//     render() {
//         return (
//             <Layout>
//                 <div>
//                     <Title level={4}>Loans</Title>
//                     <br />
//                     <Table dataSource={dataSourceLoans}>
//                         <Column
//                             title="Date"
//                             dataIndex="date"
//                             key="date"
//                         // render={(text, record, index) => (
//                         //     <span>
//                         //         {index + 1}
//                         //     </span>
//                         // )}
//                         />
//                         <Column
//                             title="Amount"
//                             dataIndex="amount"
//                             key="amount"
//                             render={(text) => (
//                                 <span>
//                                     {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(parseInt(text))}
//                                 </span>
//                             )}
//                         />
//                         <Column
//                             title="Tenure"
//                             dataIndex="tenure"
//                             key="tenure"
//                         />
//                         <Column
//                             title="Commodity"
//                             dataIndex="commodity"
//                             key="commodity"
//                         />
//                         <Column
//                             title="Status"
//                             dataIndex="status"
//                             key="status"
//                         />
//                         <Column
//                             title="Interest Rate"
//                             dataIndex="interest"
//                             key="interest"
//                         />
//                         <Column
//                             title="Actions"
//                             key="actions"
//                             render={(text, record) => (
//                                 <span>
//                                     <Tooltip title="View Loan">
//                                         <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/users/edit/${text._id}`, text)}>
//                                             View
//                                         </Button>
//                                     </Tooltip>
//                                     {/* <Popconfirm title="Are you sure you want to delete this user?" onConfirm={() => this.confirmDelete(text)} okText="Yes" cancelText="No">
//                                         <Button style={{ marginLeft: 2 }} type="default">
//                                             <Icon type="delete" style={{ color: '#ea1b2a' }} />
//                                         </Button>
//                                     </Popconfirm> */}
//                                 </span>
//                             )}
//                         />
//                     </Table>
//                 </div>
//             </Layout>

//         );
//     }
// }


export default Loans;