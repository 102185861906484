import React, { useContext, useEffect, useState } from 'react'
import { Table, Typography, Modal, Button, Form, Input, Tooltip, Icon, Popconfirm } from 'antd'
import axios from 'axios'
// import Layout from '../layout/layout'
import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'

const { Title, Text } = Typography;
const { Column } = Table;
const { TextArea } = Input;

const Commodities = () => {

    const { user } = useContext(AuthContext);
    const { commodities, dispatchCommodities, loading, setLoading, setError } = useContext(DataContext);
    const [modalVisible, setModalVisible] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [commodity, setCommodity] = useState("")
    const [commodity_desc, setCommodity_desc] = useState("")
    const [selectedId, setSelectedId] = useState(null)
    const [editing, setEditing] = useState(false)


    useEffect(() => {
        const fetchData = async () => {
            setLoading({
                type: 'LOADING', loading: true
            });
            await axios({
                url: `${config.baseUrl}/commodities`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.token}`
                },
            }).then(res => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                if (res.data.status.code !== 100) {
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                    });
                    return;
                }
                dispatchCommodities({
                    type: 'COMMODITIES_FETCHED', commodities: res.data.entity.commodities
                })

            }).catch((err) => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: err.message }
                });
            })
        };
        fetchData();
    }, [dispatchCommodities, setError, setLoading, user, refetch])

    const handleSubmit = async (edit) => {
        if (!commodity || !commodity_desc) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Commodity Name and Decription Required' }
            });
            return;
        }
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: edit ? `${config.baseUrl}/commodity/${selectedId}` : `${config.baseUrl}/commodity`,
            method: edit ? 'PUT' : 'POST',
            data: {
                commodity,
                commodity_desc
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setModalVisible(false)
            setRefetch(true)
            setLoading({
                type: 'LOADING', loading: false
            });

            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
        }).catch(err => {
            setModalVisible(false)
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })

    }
    const handleCancel = () => {
        setModalVisible(false)
        setCommodity_desc("")
        setCommodity("")
    }

    const deleteCommodity = async (id) => {
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/commodity/${id}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setLoading({
                type: 'LOADING', loading: false
            });
            dispatchCommodities({
                type: 'DELETE_COMMODITY', commodity_id: id
            })
        }).catch(err => {
            console.log(err)
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })
    }
    const viewCommodity = (commodity) => {
        setEditing(true)
        setSelectedId(commodity._id)
        setCommodity_desc(commodity.commodity_desc)
        setCommodity(commodity.commodity)
        setModalVisible(true)
    }

    return (
        // <Layout>
        <section>
            <div>
                <div className="flex-space-between">
                    <Title level={4}>Commodities</Title>
                    <Button type="primary" onClick={() => setModalVisible(true)}>Create Commodity</Button>
                </div>
                <Table
                    bordered
                    rowKey={record => record._id}
                    loading={loading}
                    style={{ borderRadius: 10, backgroundColor: 'white' }}
                    dataSource={commodities}>
                    <Column
                        title="Commodity"
                        dataIndex="commodity"
                        key="commodity"
                    />
                    <Column
                        title="Desctiption"
                        dataIndex="commodity_desc"
                        key="commodity_desc"
                    />
                    <Column
                        title="Actions"
                        key="actions"
                        render={(text, record) => (
                            <span>
                                <Tooltip title="View">
                                    <Button style={{ marginRight: 2 }} onClick={() => viewCommodity(record)}>
                                        <Icon type="eye" />
                                    </Button>
                                </Tooltip>
                                <Popconfirm title="Are you sure you want to delete this commodity?" onConfirm={() => deleteCommodity(text._id)} okText="Yes" cancelText="No">
                                    <Button type="danger" style={{ marginRight: 2 }}>
                                        <Icon type="delete" />
                                    </Button>
                                </Popconfirm>

                            </span>
                        )}
                    />
                </Table>
            </div>
            <div className="modal-container">
                <Modal
                    title="Create Commodity"
                    visible={modalVisible}
                    onOk={handleSubmit}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button loading={loading} type="primary" key="ok" onClick={() => handleSubmit(editing)}>
                            Submit
                        </Button>
                    ]}
                >
                    <Form.Item>
                        <Text>Commodity Name <Text style={{ color: 'red' }}>*</Text></Text>
                        <Input
                            value={commodity}
                            onChange={(e) => setCommodity(e.target.value)}
                            placeholder="Commodity"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Text>Commodity Description <Text style={{ color: 'red' }}>*</Text></Text>
                        <TextArea
                            value={commodity_desc}
                            onChange={(e) => setCommodity_desc(e.target.value)}
                            placeholder="Description"
                        />
                    </Form.Item>
                    <br />
                </Modal>
            </div>
        </section>
        // </Layout >
    )
}

export default Commodities