import React, { useContext, useState } from 'react'
import { Button, Slider, Row, Col, Icon, Card, Upload, message } from 'antd';
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'
import { formatCurrency } from '../../utils/formatCurrency'



const Invoice = (props) => {

    const { user } = useContext(AuthContext);
    const { loading, setLoading, setError } = useContext(DataContext);
    const [tenure, setTenure] = useState(1)
    const [loanAmount, setLoanAmount] = useState(5000000)
    const [invoice, setInvoice] = useState(null)
    const [bank_statement, setBankStatement] = useState(null)
    const [undertaking, setUndertaking] = useState(null)

    const handleSubmit = async () => {
        if (!invoice) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Please upload Invoice Document' }
            });
            return;
        }
        if (!bank_statement) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Please upload Bank Statement' }
            });
            return;
        }
        if (!undertaking) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Please upload Undertaking' }
            });
            return;
        }
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/loan`,
            method: 'POST',
            data: {
                loan_amount: loanAmount,
                loan_tenure: tenure,
                type: "INVOICE",
                invoice,
                bank_statement,
                undertaking
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setLoading({
                type: 'LOADING', loading: false
            });
            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
            props.history.push('/loans')
        }).catch(err => {
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })

    }

    const downloadFile = () => {
        message.info('Your file will be downloaded shortly')
        axios({
            url: 'https://test.tradebuza.com/files/download/farmersTest.xlsx',
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'farmersTest.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch((err) => {
            message.error("Unable to download. Please check your internet connection and try again")
        });
    }

    const calcRepayment = () => {
        if (tenure === 1) {
            let repayment = loanAmount + (0.0508 * loanAmount)
            return formatCurrency(repayment)
        }
        if (tenure === 2) {
            let repayment = loanAmount + (0.0717 * loanAmount)
            return formatCurrency(repayment)
        }
    }

    const uploadInvoice = {
        accept: '.pdf, image/*',
        name: 'file',
        action: `${config.baseUrl}/file`,
        headers: {
            "Authorization": `Bearer ${user.token}`
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {

            }
            if (info.file.status === 'done') {
                setInvoice(info.file.response.entity._id)
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const uploadBankStatement = {
        accept: '.pdf, image/*',
        name: 'file',
        action: `${config.baseUrl}/file`,
        headers: {
            "Authorization": `Bearer ${user.token}`
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {

            }
            if (info.file.status === 'done') {
                setBankStatement(info.file.response.entity._id)
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    const uploadUndertaking = {
        accept: '.pdf, image/*',
        name: 'file',
        action: `${config.baseUrl}/file`,
        headers: {
            "Authorization": `Bearer ${user.token}`
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {

            }
            if (info.file.status === 'done') {
                setUndertaking(info.file.response.entity._id)
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <div>
            <Card>
                <Card>
                    <div className="sliders">
                        <h4>Loan Amount: {formatCurrency(loanAmount)}</h4>
                        <Slider
                            min={5000000}
                            max={100000000}
                            tipFormatter={formatCurrency}
                            onChange={(val) => setLoanAmount(val)} />
                        <h4>Loan Tenure: {tenure === 1 ? `${tenure} Month` : `${tenure} Months`}</h4>
                        <Slider
                            min={1} max={2}
                            onChange={(val) => setTenure(val)} />
                    </div>

                    <br />
                    <h4>Total Repayment Due: {calcRepayment()}</h4>
                </Card>

                <br />
                <Card>
                    <Row gutter={{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <h3>Invoice</h3>
                            <h5>Upload Invoice</h5>
                            <div>
                                <Upload {...uploadInvoice}>
                                    <Button>
                                        <Icon type="upload" /> Click to Upload
                                </Button>
                                </Upload>
                                <br />
                                <h4 onClick={downloadFile} className="example">Click here to see example</h4>
                            </div>

                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <h3> Bank Statement</h3>
                            <h5>Upload bank statement for the last six months</h5>
                            <div>
                                <Upload {...uploadBankStatement}>
                                    <Button>
                                        <Icon type="upload" /> Click to Upload
                                </Button>
                                </Upload>
                                <br />
                            </div>
                            <h4 onClick={downloadFile} className="example">Click here to see example</h4>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <h3> Repayment Undertaking</h3>
                            <h5>Upload repayment undertaking signed by client</h5>
                            <div>
                                <Upload {...uploadUndertaking}>
                                    <Button>
                                        <Icon type="upload" /> Click to Upload
                                </Button>
                                </Upload>
                                <br />
                            </div>
                            <h4 onClick={downloadFile} className="example">Click here to see example</h4>
                        </Col>
                    </Row>
                </Card>

                <div style={{ marginTop: 20 }}>
                    <Button loading={loading} onClick={handleSubmit} type="primary">Submit Application</Button>
                </div>
            </Card>
        </div>
    )
}

export default withRouter(Invoice);