import React from 'react'
import { Link } from 'react-router-dom'
import './css/main.css'
import './css/scroll.css'
import './css/timeline.css'
import honeywell from './images/honeywell.png'
import google from './images/google.png'
import launchpad from './images/launchpad.png'
import wallet from './images/wallet.png'
import itanna from './images/itanna.png'
import riby from './images/riby-logo.png'
import mainframe from './images/Main.png'
import tecni from './images/tecni.jpg'


const Main = () => {
    return (
        <main>
            <header className="header-main">
                <div>
                    <nav className="navbar navbar-expand-md navigation">
                        <a className="navbar-brand" href="#home">
                            <img src="https://test.tradebuza.com/build/images/t_logo.png" alt="session-logo"
                                className="img-fluid logo-image" />
                        </a>

                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#collapsibleNavbar">
                            <div className="icon-bar"></div>
                            <div className="icon-bar"></div>
                            <div className="icon-bar"></div>
                        </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link" href="#home">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#features">What We Offer</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#how">How It Works</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#about">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#notified">Get Notified</a>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/dashboard">Log In</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
            <section id="banner">
                <div className="container">
                    <div className="banner-content">
                        <h1>Working Capital Loans for Commodities Merchants</h1>
                        <p>
                            {'\u3002 Invoice Discounting \u3002 LPO Financing'}
                        </p>
                        <button type="button" className="btn btn-lg">
                            <Link className="nav-link" to="/dashboard">Get Started</Link>
                        </button>
                    </div>
                </div>
            </section>
            <section id="features">
                <div className="container">
                    <h1>What We Offer</h1>
                    <div className="short-dash"></div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="feature-card">
                                <div className="card-body">
                                    <i className="material-icons">
                                        monetization_on
                                </i>
                                    <h4>Unlock Financing to Grow your Commodities Trading Business</h4>
                                    <p>Cut through the bottleneck of conventional financial institutions, and access loan
                                    customized to finance your commodity trading activities</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="feature-card">
                                <div className="card-body">
                                    <i className="material-icons">
                                        flash_on
                                </i>
                                    <h4>Quick, easy and seamless</h4>
                                    <p>Leverage our platform to provide supply chain visibility (from sourcing to delivery
                                    and invoicing) to key stakeholders</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="feature-card">
                                <div className="card-body">
                                    <i className="material-icons">
                                        trending_up
                                </i>
                                    <h4>Get beyond just finance</h4>
                                    <p>Connect with thousands of small holder farmers managed by our network of partners
                                    involved in different contract farming enagagements</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="how">
                <div className="container">
                    <h1>How It Works</h1>
                    <div className="short-dash"></div>
                    <div className="timeline">

                        <div className="timeline-block timeline-block-right">
                            <div className="marker"></div>
                            <div className="timeline-content">
                                <h4>Register</h4>

                                <p>Register as a merchant on www.tradebacker.com</p>
                            </div>
                        </div>

                        <div className="timeline-block timeline-block-left">
                            <div className="marker"></div>
                            <div className="timeline-content">
                                <h4>Apply for a Loan</h4>

                                <p>Select loan amount and loan tenure, upload LPO and bank statement. Choose commodity and
                                    volume
                                to be traded. Submit and receive a loan note</p>
                            </div>
                        </div>

                        <div className="timeline-block timeline-block-right">
                            <div className="marker"></div>
                            <div className="timeline-content">
                                <h4>Create Trade Activities</h4>

                                <p>Upon loan approval, set trading activities and access finance for each activity
                                    created.
                                    Generate invoice for clients after delivery and pay back total repayment
                            </p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section id="partners">
                <h1>Key Partners</h1>
                <div className="short-dash"></div>
                <div className="container">
                    <div className="row blog big-screen">
                        <div className="col-md-12">
                            <div id="blogCarousel" className="carousel slide" data-ride="carousel">

                                <ol className="carousel-indicators">
                                    <li data-target="#blogCarousel" data-slide-to="0" className="active"></li>
                                    <li data-target="#blogCarousel" data-slide-to="1"></li>
                                </ol>


                                <div className="carousel-inner">

                                    <div className="carousel-item active">
                                        <div className="row">
                                            <div className="col">
                                                <span>
                                                    <img src={honeywell} className="partner-logo" alt="partner-logo"
                                                        style={{ maxWidth: '100%' }} />
                                                </span>
                                            </div>
                                            <div className="col">
                                                <span>
                                                    <img src={google} className="partner-logo" alt="partner-logo"
                                                        style={{ maxWidth: '100%' }} />
                                                </span>
                                            </div>
                                            <div className="col">
                                                <span>
                                                    <img src={launchpad} className="partner-logo" alt="partner-logo"
                                                        style={{ maxWidth: '100%' }} />
                                                </span>
                                            </div>

                                            <div className="col">
                                                <span>
                                                    <img src={itanna} className="partner-logo" alt="partner-logo"
                                                        style={{ maxWidth: '100%' }} />

                                                </span>
                                            </div>
                                        </div>

                                    </div>


                                    <div className="carousel-item">
                                        <div className="row">
                                            <div className="col-4">
                                                <span>
                                                    <img src={riby} className="partner-logo" alt="partner-logo"
                                                        style={{ maxWidth: '100%' }} />
                                                </span>
                                            </div>
                                            <div className="col-4">
                                                <span>
                                                    <img src={tecni} className="partner-logo" alt="partner-logo"
                                                        style={{ maxWidth: '100%' }} />
                                                </span>
                                            </div>
                                            <div className="col-4">
                                                <span>
                                                    <img src={mainframe} className="partner-logo" alt="partner-logo"
                                                        style={{ maxWidth: '100%' }} />
                                                </span>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </div>


                        </div>
                    </div>

                </div>

            </section>
            <section id="about">
                <div className="container">
                    <div>
                        <h1>About Us</h1>
                        <div className="short-dash"></div>
                        <div className="about-content">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 about-img">
                                    <img src={wallet} className="wallet" alt="wallet" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <p>
                                        We provide unsecured working capital loans (through our Financing Partners) to
                                    commodities suppliers to finance their commodities trade.</p>
                                    <p>

                                        Our belief is that supply chain financing for commodities can enable increased off
                                        take of agro commodities. This helps reduce post harvest losses for smallholder
                                        farmers and helps processors procure enough raw materials needed to operate their
                                        plants at full capacity.
                                </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
            <section id="notified">
                <div className="container">
                    <h1>Get Notified</h1>
                    <div className="short-dash"></div>
                    <div className="about-content">
                        <p>Get notified when Tradebacker is fully operational</p>
                    </div>
                    <div className="form-container">
                        <form>
                            <div className="form-group">
                                <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                                    placeholder="Enter email" />
                            </div>
                            <button type="submit" className="btn btn-lg">Submit</button>
                        </form>
                    </div>
                </div>
            </section>
            <section id="contact">
                <div className="container">
                    <h1>Contact Us</h1>
                    <div className="short-dash"></div>
                    <div className="about-content">
                    </div>
                    <div className="form-container">
                        <form>
                            <div className="form-group">

                                <input type="text" className="form-control" id="name" placeholder="Your Name" /><br />
                                <input type="email" className="form-control" id="email2" aria-describedby="emailHelp"
                                    placeholder="Your email" /><br />
                                <input type="text" className="form-control" id="subject" placeholder="Subject" /><br />
                                <textarea type="text" className="form-control" id="message"
                                    placeholder="Your Message"></textarea>
                            </div>
                            <button type="submit" className="btn btn-lg">Submit</button>
                        </form>
                    </div>
                </div>
            </section>
            <section id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <h5>Tradebacker</h5>
                            <ul>
                                <li>
                                    <i className="material-icons footer-icon">
                                        home_work
                                </i>
                                    <span>
                                        2, Abebe Village Access Road, Iganmu,
                                        Lagos, Nigeria.
                                </span>
                                </li>
                                <li>
                                    <i className="material-icons footer-icon">
                                        phone
                                </i>
                                    <span>
                                        +2347060603779
                                </span>
                                </li>
                                <li>
                                    <i className="material-icons footer-icon">
                                        mail_outline
                                </i>
                                    <span>
                                        contact@tradebuza.com
                                </span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-4">
                            <h5>Quick Links</h5>
                            <ul className="quick-links">
                                <li>
                                    <a href="#features">What We Offer</a>
                                </li>
                                <li>
                                    <a href="#how">How It Works</a>
                                </li>
                                <li>
                                    <a href="#partners">Our Partners</a>
                                </li>
                                <li>
                                    <a href="#about">About Us</a>
                                </li>
                                <li>
                                    {/* <!-- <a href="#demo">Schedule a Demo</a> --> */}
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-4 align-self-end social-container">
                            <a href="https://instagram.com/tradebuza"><i
                                className="fab fa-instagram instagram social-icon"></i></a>
                            <a href="https://twitter.com/tradebuza"><i className="fab fa-twitter twitter social-icon"></i></a>
                            <a href="https://facebook.com/tradebuza"><i
                                className="fab fa-facebook-f facebook social-icon"></i></a>
                            <p>© Tradebuza 2019, All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Main;