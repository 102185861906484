import React from 'react'
import { Affix } from 'antd';
// const { Text } = Typography;



function Header2() {
    return (
        <Affix>
            <React.Fragment>
                <div className="header2">
                    <div>
                        <img src="https://test.tradebuza.com/build/images/t_logo.png" alt="session-logo" className="img-fluid" width="165" height="52.5" />
                    </div>
                </div>

            </React.Fragment>
        </Affix>


    );
}

export default Header2;