import React, { useContext, useEffect, useState } from 'react'
import { Button, Typography, Slider, Row, Col, Icon, Form, Input, Card, Select, Upload, message, List, Modal } from 'antd';
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'
import { formatCurrency } from '../../utils/formatCurrency'


const { Text } = Typography;
const { Option } = Select;


const Lpo = (props) => {
    const { user } = useContext(AuthContext);
    const { partners, dispatchPartners, commodities, dispatchCommodities, loading, setLoading, setError } = useContext(DataContext);
    const [tenure, setTenure] = useState(1)
    const [modalVisible, setModalVisible] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [commodity, setCommodity] = useState(null)
    const [partner, setPartner] = useState(null)
    const [volume, setVolume] = useState(null)
    const [name, setName] = useState("")
    const [sourcingCommodity, setSourcingCommodity] = useState(0)
    const [mobile, setMobile] = useState("")
    const [address, setAddress] = useState("")
    const [bank, setBank] = useState("")
    const [account_no, setAccount] = useState("")
    const [loanAmount, setLoanAmount] = useState(5000000)
    const [purchaseOrder, setPurchaseOrder] = useState("")
    const [bankStatement, setBankStatement] = useState("")
    const [undertaking, setUndertaking] = useState("")


    useEffect(() => {
        setLoading({
            type: 'LOADING', loading: true
        });
        const fetchData = async () => {
            await axios.all([
                axios({
                    url: `${config.baseUrl}/srp`,
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${user.token}`
                    },
                }).catch((err) => {
                    setLoading({
                        type: 'LOADING', loading: false
                    });
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: err.message }
                    });
                    console.log(err)
                }),
                axios({
                    url: `${config.baseUrl}/commodities`,
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${user.token}`
                    },
                }).catch((err) => {
                    setLoading({
                        type: 'LOADING', loading: false
                    });
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: err.message }
                    });
                    console.log(err)
                }),
            ]).then(axios.spread((res1, res2) => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                if (res1.data.status.code !== 100 || res2.data.status.code !== 100) {
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: res1.data.status.desc }
                    });
                    return;
                }
                dispatchPartners({
                    type: 'PARTNERS_FETCHED', partners: res1.data.entity.sourcing_partners
                })
                dispatchCommodities({
                    type: 'COMMODITIES_FETCHED', commodities: res2.data.entity.commodities
                })

            })).catch(err => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: err.message }
                });
                console.log(err)
            });
        };
        fetchData();
    }, [dispatchCommodities, setError, setLoading, user, dispatchPartners, refetch])

    const handleSubmit = async () => {
        if (!commodity) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Please select a commodity' }
            });
            return;
        }
        if (!volume) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Please select commodity volume' }
            });
            return;
        }
        if (!partner) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Please select Sourcing Partner' }
            });
            return;
        }
        if (!purchaseOrder) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Please upload Purchase Order Document' }
            });
            return;
        }
        if (!bankStatement) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Please upload Bank Statement' }
            });
            return;
        }
        if (!undertaking) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Please upload Repayment Undertaking' }
            });
            return;
        }
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/loan`,
            method: 'POST',
            data: {
                loan_amount: loanAmount,
                loan_tenure: tenure,
                commodity,
                type: "LPO",
                purchase_order: purchaseOrder,
                bank_statement: bankStatement,
                volume,
                sourcing_partner: partner
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            console.log(res)
            setLoading({
                type: 'LOADING', loading: false
            });
            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
            props.history.push('/loans')
        }).catch(err => {
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })

    }

    const calcRepayment = () => {
        if (tenure === 1) {
            let repayment = loanAmount + (0.0508 * loanAmount)
            return formatCurrency(repayment)
        }
        if (tenure === 2) {
            let repayment = loanAmount + (0.0717 * loanAmount)
            return formatCurrency(repayment)
        }
    }

    const uploadPurchaseOrderProps = {
        accept: '.pdf, image/*',
        name: 'file',
        action: `${config.baseUrl}/file`,
        headers: {
            "Authorization": `Bearer ${user.token}`
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                setPurchaseOrder(info.file.response.entity._id)
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const uploadBankStatementProps = {
        accept: '.pdf, image/*',
        name: 'file',
        action: `${config.baseUrl}/file`,
        headers: {
            "Authorization": `Bearer ${user.token}`
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                setBankStatement(info.file.response.entity._id)
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const uploadUndertaking = {
        accept: '.pdf, image/*',
        name: 'file',
        action: `${config.baseUrl}/file`,
        headers: {
            "Authorization": `Bearer ${user.token}`
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {

            }
            if (info.file.status === 'done') {
                setUndertaking(info.file.response.entity._id)
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const handleCancel = () => {
        setModalVisible(false)
        setName("")
        setMobile("")
    }

    const downloadFile = () => {
        message.info('Your file will be downloaded shortly')
        axios({
            url: 'https://test.tradebuza.com/files/download/farmersTest.xlsx',
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'farmersTest.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch((err) => {
            message.error("Unable to download. Please check your internet connection and try again")
        });
    }

    const addSourcingPartner = async (editing) => {
        // if (!name || !mobile) {
        //     setError({
        //         type: 'SET_ERROR', error: { type: 'error', message: 'Commodity Name and Decription Required' }
        //     });
        //     return;
        // }
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/srp`,
            method: 'POST',
            data: {
                name,
                mobile,
                commodity: sourcingCommodity,
                address,
                bank,
                account_no
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setModalVisible(false)
            setRefetch(true)
            setLoading({
                type: 'LOADING', loading: false
            });

            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
        }).catch(err => {
            setModalVisible(false)
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })

    }
    return (
        <div>
            <Card>
                <Card>
                    <div className="sliders">
                        <h4>Loan Amount: {formatCurrency(loanAmount)}</h4>
                        <Slider
                            min={5000000}
                            max={100000000}
                            tipFormatter={formatCurrency}
                            onChange={(val) => setLoanAmount(val)} />
                        <h4>Loan Tenure: {tenure === 1 ? `${tenure} Month` : `${tenure} Months`}</h4>
                        <Slider
                            min={1} max={2}
                            onChange={(val) => setTenure(val)} />
                    </div>

                    <br />
                    <h4>Total Repayment Due: {calcRepayment()}</h4>
                </Card>

                <br />
                <Card>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <h3>Purchase Order</h3>
                            <h5>Upload purchase order to be financed</h5>
                            <div>
                                <Upload {...uploadPurchaseOrderProps}>
                                    <Button>
                                        <Icon type="upload" /> Click to Upload
                                </Button>
                                </Upload>
                                <br />
                                <h4 onClick={downloadFile} className="example">Click here to download example</h4>
                            </div>

                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <h3> Bank Statement</h3>
                            <h5>Upload bank statement for the last six months</h5>
                            <div>
                                <Upload {...uploadBankStatementProps}>
                                    <Button>
                                        <Icon type="upload" /> Click to Upload
                                </Button>
                                </Upload>
                                <br />
                            </div>
                            <h4 onClick={downloadFile} className="example">Click here to download example</h4>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <h3> Repayment Undertaking</h3>
                            <h5>Upload repayment undertaking signed by client</h5>
                            <div>
                                <Upload {...uploadUndertaking}>
                                    <Button>
                                        <Icon type="upload" /> Click to Upload
                                </Button>
                                </Upload>
                                <br />
                            </div>
                            <h4 onClick={downloadFile} className="example">Click here to see example</h4>
                        </Col>
                    </Row>
                    <Form.Item
                        style={{ margin: 0, padding: 0 }}>
                        <Text> Commodity <Text style={{ color: 'red' }}>*</Text></Text><br />
                        <Select style={{ width: '50%' }} defaultValue="Select Commodity" onChange={(e) => setCommodity(e)}>
                            {commodities.map((item) => {
                                return (
                                    <Option key={item._id} value={item._id}>{item.commodity}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{ margin: 0, padding: 0 }}>
                        <Text> Volume (MT)<Text style={{ color: 'red' }}>*</Text></Text><br />
                        <Input
                            type="number"
                            style={{ width: '50%' }}
                            value={volume}
                            onChange={(e) => setVolume(e.target.value)}
                            placeholder="Volume"
                        />
                    </Form.Item>
                </Card>
                <br />
                <Card>
                    <div style={{ marginBottom: '3%', marginTop: '3%' }}>
                        <h4>Sourcing Partner</h4>
                        <h5><em>The supplier will be paid to supply your commodity upon loan approval</em></h5>
                    </div>
                    {/* user.user.role === "admin" && ) */}
                    {/* <div style={{ marginBottom: 20 }}>
                        <Button icon="plus" onClick={() => setModalVisible(true)} type="default">Add</Button>
                    </div> */}
                    <div>
                        <List
                            grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 2,
                                md: 4,
                                lg: 4,
                                xl: 4,
                                xxl: 3,
                            }}
                            dataSource={partners}
                            renderItem={item => (
                                <List.Item>
                                    <Card
                                        className="sourcing-card"
                                        style={{ backgroundColor: partner === item._id ? '#009663' : '#fff', position: 'relative' }}
                                        onClick={() => setPartner(item._id)}>
                                        {partner === item._id && <Icon className="selected-icon" type="check-circle" />}
                                        <Text style={{ display: 'block', color: partner === item._id ? '#fff' : '#000' }}>Name: {item.name}</Text>
                                        <Text style={{ display: 'block', color: partner === item._id ? '#fff' : '#000' }}>Phone Number: {item.mobile}</Text>
                                        <Text style={{ display: 'block', color: partner === item._id ? '#fff' : '#000' }}>Crop: {item.commodity.commodity}</Text>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </div>
                </Card>
                <div className="modal-container">
                    <Modal
                        title="Create Sourcing Partner"
                        visible={modalVisible}
                        onOk={handleSubmit}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="back" onClick={handleCancel}>
                                Cancel
                        </Button>,
                            <Button loading={loading} type="primary" key="ok" onClick={() => addSourcingPartner()}>
                                Ok
                        </Button>
                        ]}
                    >
                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Name <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Name"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}>
                            <Text> Commodity <Text style={{ color: 'red' }}>*</Text></Text><br />
                            <Select style={{ width: '50%' }} defaultValue={commodity ? commodity : "Select Commodity"} onChange={(e) => setSourcingCommodity(e)}>
                                {commodities.map((item) => {
                                    return (
                                        <Option key={item._id} value={item._id}>{item.commodity}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{ margin: 0 }}
                        >
                            <Text> Mobile <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                placeholder="Mobile"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}>
                            <Text> Address <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder="Address"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}>
                            <Text> Bank Name <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                value={bank}
                                onChange={(e) => setBank(e.target.value)}
                                placeholder="Bank"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ margin: 0 }}>
                            <Text> Account Number <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                value={account_no}
                                onChange={(e) => setAccount(e.target.value)}
                                placeholder="Account Number"
                            />
                        </Form.Item>
                        <br />
                    </Modal>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Button loading={loading} onClick={handleSubmit} type="primary">Submit Application</Button>
                </div>
            </Card>
        </div >
    )
}

export default withRouter(Lpo);