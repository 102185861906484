import React, { useContext, useEffect } from 'react'
import { Table, Typography, Button, Tooltip, Icon, Row, Col } from 'antd'
// import axios from 'axios'
import Layout from '../layout/Layout'
import { payments } from '../../datasources/index'
import { renderPaymentStatus } from '../loan/utils'
import { formatCurrency } from '../../utils/formatCurrency'
// import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext'
// import { DataContext } from '../../contexts/DataContext'

const { Title } = Typography;
const { Column } = Table;
// const { TextArea } = Input;

const Payments = () => {

    const { user } = useContext(AuthContext);
    // const [modalVisible, setModalVisible] = useState(false)


    useEffect(() => {
        console.log(user)
    })

    // useEffect(() => {
    //     const fetchData = async () => {
    //         setLoading({
    //             type: 'LOADING', loading: true
    //         });
    //         await axios({
    //             url: `${config.baseUrl}/commodities`,
    //             method: 'GET',
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer ${user.token}`
    //             },
    //         }).then(res => {
    //             setLoading({
    //                 type: 'LOADING', loading: false
    //             });
    //             if (res.data.status.code !== 100) {
    //                 setError({
    //                     type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
    //                 });
    //                 return;
    //             }
    //             dispatchCommodities({
    //                 type: 'COMMODITIES_FETCHED', commodities: res.data.entity.commodities
    //             })

    //         }).catch((err) => {
    //             setLoading({
    //                 type: 'LOADING', loading: false
    //             });
    //             setError({
    //                 type: 'SET_ERROR', error: { type: 'error', message: err.message }
    //             });
    //         })
    //     };
    //     fetchData();
    // }, [dispatchCommodities, setError, setLoading, user, refetch])

    // const handleSubmit = async (edit) => {
    //     if (!commodity || !commodity_desc) {
    //         setError({
    //             type: 'SET_ERROR', error: { type: 'error', message: 'Commodity Name and Decription Required' }
    //         });
    //         return;
    //     }
    //     setLoading({
    //         type: 'LOADING', loading: true
    //     });
    //     await axios({
    //         url: edit ? `${config.baseUrl}/commodity/${selectedId}` : `${config.baseUrl}/commodity`,
    //         method: edit ? 'PUT' : 'POST',
    //         data: {
    //             commodity,
    //             commodity_desc
    //         },
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${user.token}`
    //         },
    //     }).then(res => {
    //         setModalVisible(false)
    //         setRefetch(true)
    //         setLoading({
    //             type: 'LOADING', loading: false
    //         });

    //         if (res.data.status.code !== 100) {
    //             setError({
    //                 type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
    //             });
    //             return;
    //         }
    //     }).catch(err => {
    //         setModalVisible(false)
    //         setLoading({
    //             type: 'LOADING', loading: false
    //         });
    //         setError({
    //             type: 'SET_ERROR', error: { type: 'error', message: err.message }
    //         });
    //     })

    // }
    // const handleCancel = () => {
    //     setModalVisible(false)
    //     setCommodity_desc("")
    //     setCommodity("")
    // }

    // const deleteCommodity = async (id) => {
    //     setLoading({
    //         type: 'LOADING', loading: true
    //     });
    //     await axios({
    //         url: `${config.baseUrl}/commodity/${id}`,
    //         method: 'DELETE',
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${user.token}`
    //         },
    //     }).then(res => {
    //         setLoading({
    //             type: 'LOADING', loading: false
    //         });
    //         dispatchCommodities({
    //             type: 'DELETE_COMMODITY', commodity_id: id
    //         })
    //     }).catch(err => {
    //         console.log(err)
    //         setLoading({
    //             type: 'LOADING', loading: false
    //         });
    //         setError({
    //             type: 'SET_ERROR', error: { type: 'error', message: err.message }
    //         });
    //     })
    // }
    // const viewCommodity = (commodity) => {
    //     setEditing(true)
    //     setSelectedId(commodity._id)
    //     setCommodity_desc(commodity.commodity_desc)
    //     setCommodity(commodity.commodity)
    //     setModalVisible(true)
    // }

    return (
        <Layout>
            <section>
                <div>
                    {/* <div className="flex-space-between">
                        <Title level={4}>Commodities</Title>
                        <Button type="primary" onClick={() => setModalVisible(true)}>Create Commodity</Button>
                    </div> */}
                    <Row gutter={16}>
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <div className="dash-card dash-card-3">
                                <div style={{ marginLeft: '5%' }}>
                                    <h5 className="dash-card-h5 dash-card-h5-3">Total Amount</h5>
                                    <h1 className="dash-card-h1">N200,000,000</h1>
                                </div>
                            </div>
                        </Col>

                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <div className="dash-card dash-card-1">
                                <div style={{ marginLeft: '5%' }}>
                                    <h5 className="dash-card-h5 dash-card-h5-1">Total Disbursed Amount</h5>
                                    <h1 className="dash-card-h1">N12,000,000</h1>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Title level={4}>Payments</Title>
                    <Table
                        bordered
                        rowKey={record => record.key}
                        style={{ borderRadius: 10, backgroundColor: 'white' }}
                        dataSource={payments}>
                        <Column
                            title="Name"
                            dataIndex="name"
                            key="name"
                        />
                        <Column
                            title="Role"
                            dataIndex="disbursed"
                            key="disbursed"
                        />
                        <Column
                            title="Amount"
                            dataIndex="amount"
                            key="amount"
                            render={(text, record) => (
                                <span>
                                    {formatCurrency(text)}
                                </span>
                            )}
                        />
                        <Column
                            title="Bank"
                            dataIndex="bank"
                            key="bank"
                        />
                        <Column
                            title="Status"
                            dataIndex="status"
                            key="status"
                            render={(text, record) => (
                                <span>
                                    {renderPaymentStatus(text)}
                                </span>
                            )}
                        />
                        <Column
                            title="Date"
                            dataIndex="date"
                            key="date"
                        />
                        <Column
                            title="Actions"
                            key="actions"
                            render={(text, record) => (
                                <span>
                                    <Tooltip title="View">
                                        <Button style={{ marginRight: 2 }}>
                                            <Icon type="eye" />
                                        </Button>
                                    </Tooltip>
                                </span>
                            )}
                        />
                    </Table>
                </div>
                {/* <div className="modal-container">
                    <Modal
                        title="Create Commodity"
                        visible={modalVisible}
                        onOk={handleSubmit}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="back" onClick={handleCancel}>
                                Cancel
                        </Button>,
                            <Button loading={loading} type="primary" key="ok" onClick={() => handleSubmit(editing)}>
                                Submit
                        </Button>
                        ]}
                    >
                        <Form.Item>
                            <Text>Commodity Name <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                value={commodity}
                                onChange={(e) => setCommodity(e.target.value)}
                                placeholder="Commodity"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Text>Commodity Description <Text style={{ color: 'red' }}>*</Text></Text>
                            <TextArea
                                value={commodity_desc}
                                onChange={(e) => setCommodity_desc(e.target.value)}
                                placeholder="Description"
                            />
                        </Form.Item>
                        <br />
                    </Modal>
                </div> */}
            </section>
        </Layout >
    )
}

export default Payments