import React, { useContext, useState, useEffect } from 'react'
import { Form, Input, Button, Card, Typography, Steps, Row, Col, Select } from 'antd';
import { Link } from 'react-router-dom'
import axios from 'axios'
import Header2 from './Header2'
import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext';

const { Text, Title } = Typography;
const { Step } = Steps;
const { Option } = Select;

const Register = (props) => {
    const { dispatch, loading, setLoading, setError } = useContext(AuthContext);
    const [currentStep, setStep] = useState(0)
    const [email, setEmail] = useState('')
    const [first_name, setFirstName] = useState('')
    const [mobile, setMobile] = useState('')
    const [last_name, setLastName] = useState('')
    // const [company, setCompany] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [rc_number, setRCNumber] = useState('')
    const [address, setAddress] = useState('')
    const [country, setCountry] = useState('')
    const [states, setAllStates] = useState([])
    const [state, setState] = useState(0)
    const [lgas, setAllLgas] = useState([])
    const [lga, setlga] = useState(0)


    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')


    useEffect(() => {
        const fetchData = async () => {
            await axios({
                url: `${config.baseUrl}/states`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(res => {
    
                if (res.data.status.code !== 100) {
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                    });
                    return;
                }
                setAllStates(res.data.entity.states)

            }).catch((err) => {
    
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: err.message }
                });
                console.log(err)
            })
        };
        fetchData();
    }, [setError])

    const handleSubmit = async () => {
        var mailFormat = /\S+@\S+\.\S+/
        var phoneFormat = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/g;
        if (mailFormat.test(email) === false) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Invalid Email Address' }
            });
            return;
        }
        if (!email) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Email Address required' }
            });
            return;
        }
        if (phoneFormat.test(mobile) === false) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Invalid Phone Number' }
            });
            return;
        }
        if (!first_name) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'First Name required' }
            });
            return;
        }
        if (!last_name) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Last Name required' }
            });
            return;
        }
        if (!password) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Password required' }
            });
            return;
        }
        if (password !== password2) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Passwords do not match' }
            });
            return;
        }
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/account`,
            method: 'POST',
            data: {
                email,
                first_name,
                last_name,
                mobile,
                password,
                rc_number,
                business_name: businessName,
                // company_name: company,
                state,
                lga,
                country,
                business_address: address
            },
            headers: {
                "Content-Type": "application/json"
            },
        }).then(res => {
            setLoading({
                type: 'LOADING', loading: false
            });
            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
            dispatch({
                type: 'SIGN_UP', user: res.data.entity
            });
            props.history.push('/login')
        }).catch(err => {
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })
    }

    const setSelectedState = async (state_id) => {
        setState(state_id)
        await axios({
            url: `${config.baseUrl}/cities/${state_id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => {

            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
            setAllLgas(res.data.entity.cities)

        }).catch((err) => {

            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
            console.log(err)
        })
    }
    return (
        <div style={{ backgroundColor: 'white' }}>
            <Header2 />
            <div className="steps-container">
                <Steps size="small" current={currentStep} onChange={(currentStep) => setStep(currentStep)}>
                    <Step title="Account" className="step" />
                    <Step title="Business Verification" className="step" />
                    {/* <Step title="Business Information" className="step" /> */}
                </Steps>
            </div>
            {currentStep === 0 &&
                <div className="login-page-container">
                    <Card className="login-form-container">
                        <div style={{ marginBottom: 10 }}>
                            <Title level={4} >Sign Up</Title>
                        </div>
                        <Form className="login-form">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item>
                                        <Input
                                            value={first_name}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            placeholder="First Name"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <Input
                                            value={last_name}
                                            onChange={(e) => setLastName(e.target.value)}
                                            placeholder="Last Name"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Input
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    placeholder="Phone Number"
                                />
                            </Form.Item>
                            {/* <Form.Item>
                                <Input
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    placeholder="Company Name"
                                />
                            </Form.Item> */}
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item>
                                        <Input
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            type="password"
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <Input
                                            value={password2}
                                            onChange={(e) => setPassword2(e.target.value)}
                                            type="password"
                                            placeholder="Repeat Password"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button onClick={() => setStep(1)} type="primary" className="login-form-button">
                                    Sign Up
                                    </Button>
                            </Form.Item>
                            <Text>Already a user? <Link to="/login"><Text className="links">Log in</Text></Link></Text>
                        </Form>
                    </Card>
                </div>
            }

            {currentStep === 1 &&
                <div className="login-page-container">
                    <Card className="login-form-container">
                        <Form className="login-form">
                            <Form.Item>
                                <Text>Registered Business Name <Text style={{ color: 'red' }}>*</Text></Text>
                                <Input
                                    value={businessName}
                                    onChange={(e) => setBusinessName(e.target.value)}
                                    placeholder="Business Name"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Text>RC Number <Text style={{ color: 'red' }}>*</Text></Text>
                                <Input
                                    value={rc_number}
                                    onChange={(e) => setRCNumber(e.target.value)}
                                    placeholder="RC Number"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Text>Address <Text style={{ color: 'red' }}>*</Text></Text>
                                <Input
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    placeholder="Address"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item>
                                            <Select defaultValue="" onChange={(e) => setCountry(e)}>
                                                <Option value="">Country</Option>
                                                <Option value="Nigeria">Nigeria</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item>
                                            <Select defaultValue="" onChange={(e) => setSelectedState(e)}>
                                                <Option value="">Select State</Option>
                                                {states.map(item => {
                                                    return (
                                                        <Option key={item.state_id} value={item.state_id}>{item.name}</Option>
                                                    )
                                                })}

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item>
                                            <Select defaultValue="" onChange={(e) => setlga(e)}>
                                                <Option value="">Select LGA</Option>
                                                {lgas.map(item => {
                                                    return (
                                                        <Option key={item.local_id} value={item.local_id}>{item.local_name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" loading={loading} onClick={handleSubmit} className="login-form-button">
                                    Submit
                                </Button>
                            </Form.Item>
                            <Text>Already a user? <Link to="/login"><Text className="links">Log in</Text></Link></Text>
                        </Form>
                    </Card>
                </div>
            }
            {/* {currentStep === 2 &&
                <div className="login-page-container">
                    <Card className="login-form-container">
                        <Form className="login-form">
                            <Form.Item>
                                <Text>Bank Name <Text style={{ color: 'red' }}>*</Text></Text>
                                <Select defaultValue="bank">
                                    <Option value="bank">Select Bank</Option>
                                    <Option value="zenith">Zenith Bank</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Text>Bank Account Number <Text style={{ color: 'red' }}>*</Text></Text>
                                <Input
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button loading={loading} type="primary" onClick={handleSubmit} className="login-form-button">
                                    Confirm
                                </Button>
                            </Form.Item>
                            <Text>Already a user? <Link to="/login"><Text className="links">Log in</Text></Link></Text>
                        </Form>
                    </Card>
                </div>
            } */}

            <div className="footer">
                <Text>&#169; 2019 Tradebuza Inc</Text>
            </div>
        </div>
    )
}



export default Register;