import React from 'react'
import { Button, Tag } from 'antd'
import moment from 'moment'


export const dataSourceMerchant = [
  {
    key: '1',
    firstname: 'Dustin',
    lastname: 'Moskovitz',
    email: 'dustin@asana.com',
    group: 'Field Agent'

  },
  {
    key: '2',
    firstname: 'Elon',
    lastname: 'Musk',
    email: 'elon@tesla.com',
    group: 'Field Agent'
  },
];

export const dataSourceSourcing = [
  {
    key: '1',
    firstname: 'Dustin',
    lastname: 'Moskovitz',
    email: 'dustin@asana.com',
    commodity: 'Rice',
    phone: '07011640721'

  },
  {
    key: '2',
    firstname: 'Elon',
    lastname: 'Musk',
    email: 'elon@tesla.com',
    commodity: 'Maize',
    phone: '07011640721'
  },
];

export const columnsMerchant = [
  {
    title: 'FIrst Name',
    dataIndex: 'first_name',
    key: 'first_name',
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'User Group',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Action',
    render: () => (
      <span>
        <Button type="primary">View</Button>
      </span>
    )
  },
];

export const dataSourceCommodities = [
  {
    key: '1',
    commodity: 'Maize',
    volume: '500MT',
    status: 'Ongoing',
  },
  {
    key: '2',
    commodity: 'Soybean',
    volume: '200MT',
    status: 'Completed',
  },
];

export const columnsCommodities = [
  {
    title: 'Commodity',
    dataIndex: 'commodity',
    key: 'commodity',
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    key: 'volume',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => (
      <span>
        {text === "Ongoing" ? <Tag color="orange">{text}</Tag> : <Tag color="green">{text}</Tag>}
      </span>
    )
  },
  {
    title: 'Action',
    render: () => (
      <span>
        <Button type="primary">View</Button>
      </span>
    )
  },
];

export const dataSourceLoans = [
  {
    key: '1',
    date: '27/7/2019',
    amount: 20000,
    tenure: '1 month',
    commodity: 'Soybean',
    status: 'Approved',
    repayment: 100000,
    interest: "15%"

  },
  {
    key: '2',
    date: '27/7/2019',
    amount: 30000,
    tenure: '2 months',
    commodity: 'Maize',
    status: 'Processing',
    repayment: 1000000,
    interest: "10%"
  },
];

export const columnsLoans = [
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    render: (text) => (
      <span>{moment(text).format('DD/MM/YYYY')}</span>
    )
  },
  {
    title: 'Amount',
    dataIndex: 'loan_amount',
    key: 'loan_amount',
    render: (text) => (
      <span>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(text)}</span>
    )
  },
  {
    title: 'Tenure (Months)',
    dataIndex: 'loan_tenure',
    key: 'loan_tenure',
  },
  {
    title: 'Commodity',
    dataIndex: 'commodity.commodity',
    key: 'commodity.commodity',
  },
  {
    title: 'Interest Rate',
    dataIndex: 'loan_rate',
    key: 'loan_rate',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Repayment',
    dataIndex: 'loan_repayment',
    key: 'loan_repayment',
    render: (text) => (
      <span>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(parseInt(text))}</span>
    )
  },
];

export const payments = [
  {
    key: '1',
    date: '27/7/2019',
    amount: 5000000,
    bank: 'GTBank',
    name: 'Ololade Tobi',
    disbursed: 'Merchant',
    status: 1

  },
  {
    key: '2',
    date: '27/7/2019',
    amount: 7000000,
    bank: 'GTBank',
    name: 'Chisom Ekwuribe',
    disbursed: 'Sourcing Partner',
    status: 1
  },
];
