import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Table, Button, Typography, Spin } from 'antd';
import axios from 'axios'
import Layout from '../layout/Layout'
import config from '../../config/index'
import { formatCurrency } from '../../utils/formatCurrency'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'

import { columnsMerchant, columnsLoans } from '../../datasources/'

const { Title } = Typography;

const Dashboard = (props) => {
  const { user } = useContext(AuthContext);
  const { loans, dispatchLoan, merchants, dispatchMerchants, loading, setLoading, setError } = useContext(DataContext);
  const [pagination, setPagination] = useState({ pageSize: 10 })
  const [pageUrl, setPageUrl] = useState(0)

  const { role } = user.user;

  useEffect(() => {
    if (user.user.role.toLowerCase() === "admin") {
      const fetchData = async () => {
        setLoading({
          type: 'LOADING', loading: true
        });
        await axios({
          url: `${config.baseUrl}/accounts`,
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`
          },
        }).then(res => {
          setLoading({
            type: 'LOADING', loading: false
          });
          if (res.data.status.code !== 100) {
            setError({
              type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
            });
            return;
          }
          let pageData = pagination
          pageData.total = res.data.entity.total_records
          pageData.pageSizeOptions = ['10', '20', '50', '100'];
          pageData.showSizeChanger = true;
          pageData.current = parseInt(res.data.entity.current_page + 1)//sets pagination page to 1
          pageData.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`

          setPagination(pageData)

          dispatchMerchants({
            type: 'MERCHANTS_FETCHED', merchants: res.data.entity.users
          })
        }).catch((err) => {
          setLoading({
            type: 'LOADING', loading: false
          });
          setError({
            type: 'SET_ERROR', error: { type: 'error', message: err.message }
          });
        })
      };
      fetchData();
    }
    if (user.user.role.toLowerCase() === "merchant") {
      const fetchData = async () => {
        setLoading({
          type: 'LOADING', loading: true
        });
        await axios({
          url: `${config.baseUrl}/loans/user`,
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`
          },
        }).then(res => {
          setLoading({
            type: 'LOADING', loading: false
          });
          if (res.data.status.code !== 100) {
            setError({
              type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
            });
            return;
          }
          dispatchLoan({
            type: 'LOANS_FETCHED', loans: res.data.entity.loans
          })
        }).catch((err) => {
          setLoading({
            type: 'LOADING', loading: false
          });
          setError({
            type: 'SET_ERROR', error: { type: 'error', message: err.message }
          });
        })
      };
      fetchData();
    }

  }, [dispatchMerchants, setError, setLoading, user, dispatchLoan, pagination, setPagination])

  const handleMerchantsTableChange = async (newPagination) => {
    const pager = pagination
    pager.pageSize = newPagination.pageSize
    pager.current = newPagination.current;

    await setPagination(pager)
    await setPageUrl(newPagination.current - 1)
    fetchMore(newPagination.current - 1)
  }

  
  //FetchMore

  const fetchMore = async (newPage) => {
    console.log(newPage)
    setLoading({
      type: 'LOADING', loading: true
    });
    await axios({
      url: `${config.baseUrl}/accounts?${pageUrl === 0 ? '' : `page=${newPage}`}&per_page=${pagination.pageSize}`,
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user.token}`
      },
    }).then(res => {
      setLoading({
        type: 'LOADING', loading: false
      });
      if (res.data.status.code !== 100) {
        setError({
          type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
        });
        return;
      }
      let pageData = pagination
      pageData.total = res.data.entity.total_records
      pageData.pageSizeOptions = ['10', '20', '50', '100'];
      pageData.showSizeChanger = true;
      pageData.current = parseInt(res.data.entity.current_page + 1)//sets pagination page to 1
      pageData.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`

      setPagination(pageData)

      dispatchMerchants({
        type: 'MERCHANTS_FETCHED', merchants: res.data.entity.users
      })
    })
  }


  const calculateLoan = (type) => {
    let sum = 0;
    if (type === "total") {
      loans.map(loan => sum += parseInt(loan.loan_amount))

      return formatCurrency(`${sum}`);
    }

    if (type === "repayment") {
      loans.map(loan => sum += parseInt(loan.loan_repayment))

      return formatCurrency(`${sum}`);
    }
    return formatCurrency(`${sum}`);
  }
  return (
    <Layout>
      <section>
        <div className="flex-space-between">
          <Title style={{ fontWeight: 700 }} level={3}>Dashboard</Title>
          {role.toLowerCase() === "merchant" && <Button onClick={() => props.history.push('/loans/apply')} type="primary">Apply for Loan</Button>}
        </div>
        <Row gutter={16}>
          {role.toLowerCase() === "admin" &&
            <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
              <div className="dash-card dash-card-3">
                <div style={{ marginLeft: '5%' }}>
                  <h5 className="dash-card-h5 dash-card-h5-3">Total Merchants</h5>
                  <h1 className="dash-card-h1">{loading ? <Spin /> : pagination.total ? pagination.total : 0}</h1>
                </div>
              </div>
            </Col>
          }

          <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
            <div className="dash-card dash-card-1">
              <div style={{ marginLeft: '5%' }}>
                <h5 className="dash-card-h5 dash-card-h5-1">Total Loan Amount</h5>
                <h1 className="dash-card-h1">{loading ? <Spin /> : calculateLoan("total")}</h1>
              </div>
            </div>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
            <div className="dash-card dash-card-2">
              <div style={{ marginLeft: '5%' }}>
                <h5 className="dash-card-h5 dash-card-h5-2">Total Repayment</h5>
                <h1 className="dash-card-h1">{loading ? <Spin /> : calculateLoan("repayment")}</h1>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <div style={{ height: 50 }}></div>
      {role.toLowerCase() === "merchant" &&
        <section>
          <div className="dash-table-container">
            <div className="flex-space-between">
              <Title level={4}>Loans</Title>
            </div>
            <Table
              loading={loading}
              bordered
              style={{ borderRadius: 10, backgroundColor: 'white', }}
              dataSource={loans} columns={columnsLoans} />
          </div>
          {/* <div className="dash-table-container">
            <Title level={4}>Commodities Traded</Title>
            <Table
              bordered
              style={{ backgroundColor: 'white', borderRadius: 10 }}
              dataSource={dataSourceCommodities} columns={columnsCommodities} />
          </div> */}
        </section>
      }
      {role.toLowerCase() === "admin" &&
        <section>
          <div className="dash-table-container">
            <Title level={4}>Commodity Traders</Title>
            <Table
              onChange={handleMerchantsTableChange}
              pagination={pagination}
              bordered
              style={{ backgroundColor: 'white', borderRadius: 10 }}
              rowKey={record => record._id}
              loading={loading}
              dataSource={merchants} columns={columnsMerchant} />
          </div>
        </section>
      }


    </Layout>
  )
}


export default Dashboard;