import React, { useContext, useEffect, useState } from 'react'
import { Typography, Button, Tag, Form, Select, Spin, Empty } from 'antd';
import { Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'
import axios from 'axios'

import Layout from '../layout/Layout'
import config from '../../config/index'

const { Title, Text } = Typography;
const { Option } = Select;

const Trades = () => {
    const { user } = useContext(AuthContext);
    const { trades, dispatchTrades, loading, setLoading, setError } = useContext(DataContext);

    const [tradeData, setTradeData] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            setLoading({
                type: 'LOADING', loading: true
            });
            await axios({
                url: `${config.baseUrl}/trades`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.token}`
                },
            }).then(res => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                if (res.data.status.code !== 100) {
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                    });
                    return;
                }
                dispatchTrades({
                    type: 'TRADES_FETCHED', trades: res.data.entity
                })
                setTradeData(res.data.entity);

            }).catch((err) => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: err.message }
                });
            })
        };
        fetchData();
    }, [dispatchTrades, setError, setLoading, user])

    const renderStatus = (status) => {
        if (status === 0) {
            return <h4>Status:  <Tag color="orange">Not Trading</Tag></h4>
        }
        if (status === 1) {
            return <h4>Status:  <Tag color="blue">Ongoing</Tag></h4>
        }
        else {
            return <h4>Status:  <Tag color="green">Completed</Tag></h4>
        }
    }

    const filterTrades = (e) => {
        console.log(e)
        if (e === "1") {
            setTradeData(trades.filter(item => item.trade_status === 1))
            return
        }
        else if (e === "2") {
            setTradeData(trades.filter(item => item.trade_status === 2))
            return
        }
        else {
            setTradeData(trades)
            return
        }
    }
    return (
        <Layout>
            <div>
                <div className="flex-space-between">
                    <Title level={4}>Trades</Title>
                    <Form.Item
                        style={{ margin: 0 }}
                        colon={false}
                        label={<Text>Filter</Text>}>
                        <Select defaultValue="all" style={{ width: 300 }} onChange={(e) => filterTrades(e)}>
                            <Option value="all">All</Option>
                            <Option value="1">Ongoing</Option>
                            <Option value="2">Completed</Option>
                        </Select>
                    </Form.Item>
                </div>
                <br />
                {loading ?
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div> :
                    <div className="card-container">
                        {tradeData.map(trade => {
                            return (
                                <div key={trade._id} className="trade-container">
                                    <div>
                                        <Title level={3}>{trade.commodity.commodity}</Title>
                                    </div>
                                    <div className="trade-container-right">
                                        <div>
                                            <h4>Volume: {trade.volume}MT</h4>
                                            <h4>Source: {`${trade.sourcing_partner.address}`}</h4>
                                            {renderStatus(trade.trade_status)}
                                        </div>
                                        <Link
                                            to={{
                                                pathname: `/trades/${trade._id}`,
                                                state: trade
                                            }}
                                        >
                                            <Button type="primary">View</Button>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                        {!tradeData.length && <Empty />}
                    </div>

                }
            </div>
        </Layout>
    )
}


export default Trades;