import React, { useState, useEffect, useContext } from 'react'
import { Typography, Form, Row, Col, Input, DatePicker, Button, Icon, Modal, Table } from 'antd';
import axios from 'axios'
import uuid from 'uuid/v4'
import Layout from '../layout/Layout'
import config from '../../config/index'
import ReviewInvoice from './ReviewInvoice';
import { formatCurrency } from '../../utils/formatCurrency'
import { DataContext } from '../../contexts/DataContext'
import { AuthContext } from '../../contexts/AuthContext'


const { Title, Text } = Typography;
const { Column } = Table;

const NewInvoice = () => {
    const { user } = useContext(AuthContext);
    const { setError } = useContext(DataContext);
    const [modalVisible, setModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [client_email, setClientEmail] = useState("")
    const [client_name, setClientName] = useState("")
    const [client_company, setClientCompany] = useState("")
    const [client_phone, setClientPhone] = useState("")
    const [client_address, setClientAddress] = useState("")
    const [client_state, setClientState] = useState("")
    const [client_country, setClientCountry] = useState("")
    const [date_issued, setDateIssued] = useState("")
    const [date_due, setDateDue] = useState("")
    const [remarks, setRemarks] = useState("")
    const [invoice_items, setInvoiceItems] = useState([])
    const [sub_total, setSubTotal] = useState(0)
    const [total] = useState(0)
    const [vat, setVat] = useState(0)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [qty, setQty] = useState(0)
    const [price, setPrice] = useState(0)


    useEffect(() => {
        if (!invoice_items.length) {
            return;
        }
        if (invoice_items.length === 1) {
            const sum = invoice_items[0].total
            setSubTotal(sum)
            return;
        }
        if (invoice_items.length > 1) {
            let sum = invoice_items.reduce(function (prev, cur) {
                return prev + cur.total;
            }, 0);
            setSubTotal(sum)
            return;
        }
    }, [invoice_items, sub_total, total, vat]);

    const handleCancel = () => {
        setModalVisible(false)
    }

    const reviewInvoiceData = () => {
        if (!invoice_items.length) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: "Please add items to your invoice" }
            });
            return
        }
        setModalVisible(true)
    }

    const addItem = () => {
        if (!title || !description || !price || !qty) {
            return
        }
        const newItem = {
            id: uuid(),
            title,
            description,
            qty,
            price,
            total: price * qty
        }
        const newInvoiceItems = [...invoice_items, newItem]
        setInvoiceItems(newInvoiceItems)
        setTitle("")
        setDescription("")
        setQty(0)
        setPrice(0)
    }

    const deleteItem = (value) => {
        const newInvoiceItems = invoice_items.filter((item => item.id !== value.id))
        setInvoiceItems(newInvoiceItems)
        setSubTotal(sub_total - value.total)
    }

    const handleSubmit = () => {
        let newInvoiceItems = invoice_items.map(item => ({
            product_title: item.title,
            product_desc: item.description,
            qty: item.qty,
            price: item.price,
            total: item.total
        }));
        setModalVisible(false)
        setLoading(true)

        axios({
            url: `${config.baseUrl}/invoice`,
            method: 'POST',
            data: {
                client_email,
                client_name,
                client_company,
                client_phone,
                client_address,
                client_state,
                client_country,
                date_issued,
                date_due,
                remarks,
                invoice_items: newInvoiceItems,
                sub_total,
                vat
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setLoading(false)
            openInvoice(res.data.entity.invoice_document)
        }).catch(err => {
            setLoading(false)
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })
    }

    const openInvoice = (invoice) => {
        window.open(invoice, "_blank")
    }

    return (
        <Layout>
            <div>
                <Title level={4}>Create New Invoice</Title>
                <br />
                <div className="box-shadow">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                style={{ margin: 5, padding: 0 }}
                                colon={false}
                                label={<Text>Enter Client Name <Text style={{ color: 'red' }}>*</Text></Text>}>
                                <Input
                                    value={client_name}
                                    onChange={(e) => setClientName(e.target.value)}
                                    placeholder="Client Name" />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: 5, padding: 0 }}
                                colon={false}
                                label={<Text>Enter Client Company Name <Text style={{ color: 'red' }}>*</Text></Text>}>
                                <Input
                                    value={client_company}
                                    onChange={(e) => setClientCompany(e.target.value)}
                                    placeholder="Client Company" />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: 5, padding: 0 }}
                                colon={false}
                                label={<Text>Enter Client Address <Text style={{ color: 'red' }}>*</Text></Text>}>
                                <Input
                                    value={client_address}
                                    onChange={(e) => setClientAddress(e.target.value)}
                                    placeholder="Client Phone" />
                            </Form.Item>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item style={{ margin: 5, padding: 0 }} label={<Text>Date Issued <Text style={{ color: 'red' }}>*</Text></Text>}>
                                        <DatePicker

                                            onChange={(d, ds) => setDateIssued(ds)}
                                            format="YYYY-MM-DD" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item style={{ margin: 5, padding: 0 }} label={<Text>Due Date <Text style={{ color: 'red' }}>*</Text></Text>}>
                                        <DatePicker

                                            onChange={(d, ds) => setDateDue(ds)}
                                            format="YYYY-MM-DD" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ margin: 5, padding: 0 }}
                                colon={false}
                                label={<Text>Enter Client Email <Text style={{ color: 'red' }}>*</Text></Text>}>
                                <Input
                                    value={client_email}
                                    onChange={(e) => setClientEmail(e.target.value)}
                                    placeholder="Client Email" />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: 5, padding: 0 }}
                                colon={false}
                                label={<Text>Enter Client Phone <Text style={{ color: 'red' }}>*</Text></Text>}>
                                <Input
                                    value={client_phone}
                                    onChange={(e) => setClientPhone(e.target.value)}
                                    placeholder="Client Phone" />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: 5, padding: 0 }}
                                colon={false}
                                label={<Text>Enter Client State <Text style={{ color: 'red' }}>*</Text></Text>}>
                                <Input
                                    value={client_state}
                                    onChange={(e) => setClientState(e.target.value)}
                                    placeholder="Client State" />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: 5, padding: 0 }}
                                colon={false}
                                label={<Text>Enter Client Country <Text style={{ color: 'red' }}>*</Text></Text>}>
                                <Input
                                    value={client_country}
                                    onChange={(e) => setClientCountry(e.target.value)}
                                    placeholder="Client Country" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <Title level={4}>Items</Title>
                    <Row gutter={8}>
                        <Col span={5}>
                            <Form.Item
                                style={{ margin: 5, padding: 0 }}
                                colon={false}
                                label={<Text>Title <Text style={{ color: 'red' }}>*</Text></Text>}>
                                <Input
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Title" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                style={{ margin: 5, padding: 0 }}
                                colon={false}
                                label={<Text>Description</Text>}>
                                <Input
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Description" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item style={{ margin: 5, padding: 0 }} label={<Text>Quantity <Text style={{ color: 'red' }}>*</Text></Text>}>
                                <Input
                                    value={qty}
                                    onChange={(e) => setQty(e.target.value)}
                                    type="number" placeholder="0" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item style={{ margin: 5, padding: 0 }} label={<Text>Price<Text style={{ color: 'red' }}>*</Text></Text>}>
                                <Input
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                    type="number" placeholder="0" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={2}>
                            <Form.Item style={{ margin: 5, padding: 0 }} label={<Text>Tax (%) <Text style={{ color: 'red' }}>*</Text></Text>}>
                                <Input type="number" placeholder="5%" />
                            </Form.Item>
                        </Col> */}
                        <Col span={2}>
                            <Form.Item style={{ margin: 5, padding: 0 }} label="Action">
                                <Button onClick={addItem} type="primary"><Icon type="plus" /></Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <div>
                        <Table
                            pagination={invoice_items > 5}
                            style={{ borderRadius: 10, backgroundColor: 'white' }}
                            bordered
                            rowKey={(record, index) => index}
                            dataSource={invoice_items}>
                            <Column
                                title="Product"
                                dataIndex="title"
                                key="title"
                                render={(text, record) => {
                                    return (
                                        <span>
                                            {text}
                                        </span>
                                    )
                                }}
                            />
                            <Column
                                title="Description"
                                dataIndex="description"
                                key="description"
                                render={(text, record) => (
                                    <span>
                                        {text}
                                    </span>
                                )}
                            />
                            <Column
                                title="Quantity"
                                dataIndex="qty"
                                key="qty"
                                render={(text, record) => (
                                    <span>
                                        {text}
                                    </span>
                                )}
                            />
                            <Column
                                title="Price"
                                dataIndex="price"
                                key="price"
                                render={(text, record) => (
                                    <span>
                                        {formatCurrency(text)}
                                    </span>
                                )}
                            />
                            <Column
                                title="Total"
                                dataIndex="total"
                                key="total"
                                render={(text, record) => (
                                    <span>
                                        {formatCurrency(text)}
                                    </span>
                                )}
                            />
                            <Column
                                title=""
                                key="actions"
                                render={(text, record) => (
                                    <span>
                                        <Button onClick={() => deleteItem(text)} type="danger" style={{ marginRight: 2 }}>
                                            <Icon type="delete" />
                                        </Button>
                                    </span>
                                )}
                            />
                        </Table>
                    </div>
                    <div className="remarks-cont">
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item style={{ margin: 5, padding: 0 }} label={<Text>Remarks <Text style={{ color: 'red' }}>*</Text></Text>}>
                                    <Input
                                        value={remarks}
                                        onChange={(e) => setRemarks(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <div className="box-shadow">
                                    <h3 style={{ fontWeight: 'bold' }}><span>Sub-Total: {formatCurrency(sub_total)}</span></h3>
                                    <br />
                                    <Row gutter={12}>
                                        <Col span={4}><h3 style={{ fontWeight: 'bold' }}>VAT(%):</h3></Col>
                                        <Col span={20}>
                                            <Input
                                                style={{ width: '20%' }}
                                                value={vat}
                                                onChange={(e) => setVat(e.target.value)}
                                                type="number" placeholder="0" />
                                        </Col>
                                    </Row>
                                    <br />
                                    <h3 style={{ fontWeight: 'bold' }}>Total: {formatCurrency((vat / 100 * sub_total) + sub_total)}</h3>
                                </div>
                                <div>
                                    <Button loading={loading} onClick={() => reviewInvoiceData()} type="primary" icon="save">
                                        Review
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div>
                    <Modal
                        style={{ top: 20 }}
                        width={750}
                        visible={modalVisible}
                        onCancel={handleCancel}
                        // onOk={handleSubmit}
                        footer={null}
                    >
                        <ReviewInvoice
                            onSubmit={handleSubmit}
                            items={invoice_items}
                            client_address={client_address}
                            client_company={client_company}
                            client_country={client_country}
                            client_state={client_state}
                            client_name={client_name}
                            remarks={remarks}
                            sub_total={sub_total}
                            vat={vat}
                            date_issued={date_issued}
                            date_due={date_due}
                        />
                    </Modal>
                </div>
            </div>
        </Layout>

    );
}


export default NewInvoice;