import React from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    InfoWindow,
    Marker
} from "react-google-maps";
import { Tag } from 'antd'
import { formatCurrency } from '../../utils/formatCurrency'
import config from '../../config/index'

const renderStatus = (status) => {
    if (status === 0) {
        return <h4>Status:  <Tag color="orange">Not Trading</Tag></h4>
    }
    if (status === 1) {
        return <h4>Status:  <Tag color="blue">Trading</Tag></h4>
    }
    else {
        return <h4>Status:  <Tag color="green">Completed</Tag></h4>
    }
}


const CustomSkinMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={6}
            defaultCenter={new window.google.maps.LatLng(10.6940905, 7.3209064)}
        >
            {props.activities.map(activity => {
                return (
                    <Marker
                        label={activity.activity.charAt(0)}
                        onClick={() => {
                            props.setActiveMarker(activity._id)
                        }}
                        key={activity._id}
                        position={{ lat: parseFloat(activity.lat), lng: parseFloat(activity.lng) }}
                    >
                        {props.activeMarker === activity._id &&
                            <InfoWindow>
                                <div className="">
                                    <h4>Activity: {activity.activity}</h4>
                                    <h4>Amount: {formatCurrency(activity.amount)}</h4>
                                    <h4>Comment: {activity.comment}</h4>
                                    <h4>Date Tracked: {activity.date_tracked}</h4>
                                    <h4>Staff: {activity.staff}</h4>
                                    {renderStatus(activity.status)}
                                </div>
                            </InfoWindow>
                        }
                    </Marker>
                )

            })}
        </GoogleMap>
    ))
);

class Maps extends React.Component {

    state = {
        activeMarker: 0
    }

    setActiveMarker = (markerid) => {
        this.setState({ activeMarker: markerid })
        console.log(markerid)
    }
    render() {
        return (
            <CustomSkinMap
                activeMarker={this.state.activeMarker}
                activities={this.props.activities}
                setActiveMarker={this.setActiveMarker}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.apiKey}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `70vh` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        )
    }
}

export default Maps
