import React, { useContext, useEffect, useState } from 'react'
import { Table, Typography, Modal, Button, Form, Input, Tooltip, Icon, Popconfirm } from 'antd'
import axios from 'axios'
import Layout from '../layout/Layout'
import config from '../../config/index'
import { AuthContext } from '../../contexts/AuthContext'
import { DataContext } from '../../contexts/DataContext'

const { Title, Text } = Typography;
const { Column } = Table;

const TradeActivities = () => {

    const { user } = useContext(AuthContext);
    const { activities, dispatchActivities, loading, setLoading, setError } = useContext(DataContext);
    const [modalVisible, setModalVisible] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [activity, setActivity] = useState("")
    const [selectedId, setSelectedId] = useState(null)
    const [editing, setEditing] = useState(false)


    useEffect(() => {
        const fetchData = async () => {
            setLoading({
                type: 'LOADING', loading: true
            });
            await axios({
                url: `${config.baseUrl}/activities`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.token}`
                },
            }).then(res => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                if (res.data.status.code !== 100) {
                    setError({
                        type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                    });
                    return;
                }
                dispatchActivities({
                    type: 'ACTIVITIES_FETCHED', activities: res.data.entity.activities
                })

            }).catch((err) => {
                setLoading({
                    type: 'LOADING', loading: false
                });
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: err.message }
                });
            })
        };
        fetchData();
    }, [dispatchActivities, setError, setLoading, user, refetch])

    const handleSubmit = async (edit) => {
        if (!activity) {
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: 'Actvity Name is Required' }
            });
            return;
        }
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: edit ? `${config.baseUrl}/activity/${selectedId}` : `${config.baseUrl}/activity`,
            method: edit ? 'PUT' : 'POST',
            data: {
                activity,
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setModalVisible(false)
            setRefetch(true)
            setLoading({
                type: 'LOADING', loading: false
            });

            if (res.data.status.code !== 100) {
                setError({
                    type: 'SET_ERROR', error: { type: 'error', message: res.data.status.desc }
                });
                return;
            }
        }).catch(err => {
            setModalVisible(false)
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })

    }
    const handleCancel = () => {
        setModalVisible(false)
        setActivity("")
    }

    const deleteActivity = async (id) => {
        setLoading({
            type: 'LOADING', loading: true
        });
        await axios({
            url: `${config.baseUrl}/activity/${id}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setLoading({
                type: 'LOADING', loading: false
            });
            dispatchActivities({
                type: 'DELETE_ACTIVITY', activity_id: id
            })
        }).catch(err => {
            console.log(err)
            setLoading({
                type: 'LOADING', loading: false
            });
            setError({
                type: 'SET_ERROR', error: { type: 'error', message: err.message }
            });
        })
    }
    const viewActivity = (activity) => {
        setEditing(true)
        setSelectedId(activity._id)
        setActivity(activity.activity)
        setModalVisible(true)
    }

    return (
        <Layout>
            <section>
                <div>
                    <div className="flex-space-between">
                        <Title level={4}>Trade Activities</Title>
                        <Button type="primary" onClick={() => setModalVisible(true)}>Create Activity</Button>
                    </div>
                    <Table
                        bordered
                        rowKey={record => record._id}
                        loading={loading}
                        style={{ borderRadius: 10, backgroundColor: 'white' }}
                        dataSource={activities}>
                        <Column
                            title="Activity Name"
                            dataIndex="activity"
                            key="activity"
                        />
                        <Column
                            title="Actions"
                            key="actions"
                            render={(text, record) => (
                                <span>
                                    <Tooltip title="View">
                                        <Button style={{ marginRight: 2 }} onClick={() => viewActivity(record)}>
                                            <Icon type="eye" />
                                        </Button>
                                    </Tooltip>
                                    <Popconfirm title="Are you sure you want to delete this trade activity?" onConfirm={() => deleteActivity(text._id)} okText="Yes" cancelText="No">
                                        <Button type="danger" style={{ marginRight: 2 }}>
                                            <Icon type="delete" />
                                        </Button>
                                    </Popconfirm>
                                </span>
                            )}
                        />
                    </Table>
                </div>
                <div className="modal-container">
                    <Modal
                        title="Activity"
                        visible={modalVisible}
                        onOk={handleSubmit}
                        onCancel={handleCancel}
                        footer={!editing && [
                            <Button key="back" onClick={handleCancel}>
                                Cancel
                        </Button>,
                            <Button loading={loading} type="primary" key="ok" onClick={() => handleSubmit(editing)}>
                                Submit
                        </Button>
                        ]}
                    >
                        <Form.Item>
                            <Text>Activity Name <Text style={{ color: 'red' }}>*</Text></Text>
                            <Input
                                disabled={editing ? true : false}
                                value={activity}
                                onChange={(e) => setActivity(e.target.value)}
                                placeholder="Activity"
                            />
                        </Form.Item>
                        <br />
                    </Modal>
                </div>
            </section>
        </Layout >
    )
}

export default TradeActivities