import React, { useContext } from 'react'
import { Tabs, Typography } from 'antd';

import { AuthContext } from '../../contexts/AuthContext'
import Profile from './Profile'
import FieldStaff from './FieldStaff'
import Commodities from '../commodities/Commodities'
import Business from './Business'
import Layout from '../layout/Layout'
import SourcingPartners from '../sourcingPartners/SourcingPartners';
import Users from '../users/Users'

const { TabPane } = Tabs;
const { Title } = Typography;

const Settings = () => {

    const { user } = useContext(AuthContext);
    return (
        <Layout>
            <div>
                <Title level={3}>Configuration</Title>
            </div>
            <br />
            {user.user.role.toLowerCase() === "merchant" &&
                <Tabs type="card">
                    <TabPane tab="Profile" key="1">
                        <Profile />
                    </TabPane>
                    <TabPane tab="Business" key="2">
                        <Business />
                    </TabPane>
                    <TabPane tab="Field Staff" key="4">
                        <FieldStaff />
                    </TabPane>
                </Tabs>
            }

            {user.user.role.toLowerCase() === "admin" &&
                <Tabs type="card">
                    <TabPane tab="Profile" key="3">
                        <Profile />
                    </TabPane>
                    <TabPane tab="Commodities" key="5">
                        <Commodities />
                    </TabPane>
                    <TabPane tab="Sourcing Partners" key="6">
                        <SourcingPartners />
                    </TabPane>
                    <TabPane tab="Users" key="7">
                        <Users />
                    </TabPane>
                </Tabs>
            }
        </Layout >

    )
}

export default Settings;