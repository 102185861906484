import React, { useContext, useState } from 'react'
import { Layout, Menu } from 'antd';
import { withRouter, NavLink } from 'react-router-dom'
import Header from '../auth/Header'
import { AuthContext } from '../../contexts/AuthContext'
import { items, itemsMerchant } from './routeItems'
const { Sider } = Layout;

const Item = ({ to, children, exact = false, ...props }) => (
    <Menu.Item {...props}>
        <NavLink activeClassName="active" to={to} exact={exact}>
            {children}
        </NavLink>
    </Menu.Item>
)


const renderMenuItemChildren = ({ icon, title }) => (
    <>
        <span data-testid={title.toLowerCase + '-nav'}>{title}</span>
    </>
)
const renderMenuItem = item => {
    return item.submenu ? (
        <Menu.SubMenu key={item.path} title={renderMenuItemChildren(item)}>
            {item.submenu.map(renderMenuItem)}
        </Menu.SubMenu>
    ) : (
            <Item key={item.path} to={item.path} exact={item.exact}>
                {item.children ? item.children : renderMenuItemChildren(item)}
            </Item>
        )
}

const AppLayout = (props) => {
    const { user } = useContext(AuthContext);
    const [root] = useState(props.location.pathname.split('/')[1]);


    const renderMenu = () => {
        const { role } = user.user
        if (role.toLowerCase() === "admin") {
            return (
                <Menu mode="inline"
                    defaultOpenKeys={[root]}
                    style={{ margin: '20px 0 0' }}
                    selectedKeys={[root, props.location.pathname]}>
                    {items.map(renderMenuItem)}
                </Menu>
            )
        }
        if (role.toLowerCase() === "merchant") {
            return (
                <Menu mode="inline"
                    defaultOpenKeys={[root]}
                    style={{ margin: '20px 0 0' }}
                    selectedKeys={[root, props.location.pathname]}>
                    {itemsMerchant.map(renderMenuItem)}
                </Menu>
            )
        }
    }
    return (
        <Layout className="main-layout">

            <Sider
                width={220}
                className="sider"
                id="sider"
                breakpoint="lg"
                collapsedWidth="0"
            >
                <div>
                    <img src="https://test.tradebuza.com/build/images/t_logo.png" alt="session-logo" className="img-fluid" width="165" height="52.5" />
                </div>
                {renderMenu()}
            </Sider>
            <Layout style={{ backgroundColor: '#fafafa' }}>
                <Header />
                <div id="content">
                    {props.children}
                </div>
                {/* <div className="footer-layout">
                        <Text>&#169; 2019 Tradebuza Inc</Text>
                    </div> */}
            </Layout>
        </Layout>
    )
}


export default withRouter(AppLayout)