import React, { useContext } from 'react'
import { Row, Col, Divider, Typography, Button } from 'antd'
import { formatCurrency } from '../../utils/formatCurrency'
import { AuthContext } from '../../contexts/AuthContext'
import './invoice.css'

const { Title } = Typography;

const ReviewInvoice = (props) => {
    const { user } = useContext(AuthContext);
    return (
        <div className="invoice">
            <div className="invoice-container">
                <div className="invoice-content">
                    <div className="logo-container">
                        <div className="logo-image">
                            <img alt="company-logo" src="https://images.fastcompany.net/image/upload/w_596,c_limit,q_auto:best,f_auto/fc/3034007-inline-i-applelogo.jpg" />
                        </div>
                        <div>
                            <h2>SALE INVOICE</h2>
                            <div className="invoice-data">
                                <h4>Reference: </h4>
                                <h4>INV-55033054</h4>
                            </div>
                            <div className="invoice-data">
                                <h4>Billing Date: </h4>
                                <h4>{props.date_issued}</h4>
                            </div>
                            <div className="invoice-data">
                                <h4>Due Date: </h4>
                                <h4>{props.date_due}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="billing-container">
                        <Row gutter={24}>
                            <Col span={12}>
                                <Title style={{ color: 'green', fontWeight: '900' }} level={4}>BILLING FROM</Title>
                                <Divider style={{ backgroundColor: 'green' }} />
                                <h3>{user.user.first_name} {user.user.last_name}</h3>
                                <h4>{user.user.company_name}</h4>
                                <h4>{user.user.business_address}</h4>
                                <h4>{}</h4>
                            </Col>
                            <Col span={12}>
                                <Title style={{ color: 'green', fontWeight: '900' }} level={4}>BILLING TO</Title>
                                <Divider style={{ backgroundColor: 'green' }} />
                                <h3>{props.client_name}</h3>
                                <h4>{props.client_company}</h4>
                                <h4>{props.client_address}</h4>
                                <h4>{props.client_state}</h4>
                                <h4>{props.client_country}</h4>
                            </Col>
                        </Row>
                    </div>

                    <div className="invoice-table">
                        <table>
                            <tbody>
                                <tr>
                                    <th>Product</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                </tr>
                                {props.items.map(item => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.title}</td>
                                            <td>{item.qty}</td>
                                            <td>{formatCurrency(item.price)}</td>
                                            <td>{item.total}</td>
                                        </tr>
                                    )
                                })}
                                <tr className="table-row-no-bg ">
                                    <th className="table-head-no-border"></th>
                                    <th className="table-head-no-border"></th>
                                    <th>Sub-Total</th>
                                    <th>{formatCurrency(props.sub_total)}</th>
                                </tr>
                                <tr className="table-row-no-bg">
                                    <th className="table-head-no-border"></th>
                                    <th className="table-head-no-border"></th>
                                    <th >VAT {props.vat}%</th>
                                    <th>{formatCurrency((props.vat / 100 * props.sub_total))}</th>
                                </tr>
                                <tr className="table-row-no-bg">
                                    <th className="table-head-no-border"></th>
                                    <th className="table-head-no-border"></th>
                                    <th className="colored-th">Total Due</th>
                                    <th className="colored-th">
                                        {formatCurrency((props.vat / 100 * props.sub_total) + props.sub_total)}
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="invoice-notice">
                        <h4>IMPORTANT NOTICE</h4>
                        <hr />
                        <h5>{props.remarks}</h5>
                    </div>
                </div>
            </div>
            <Button onClick={() => props.onSubmit()} type="primary">Submit</Button>
        </div>
    )
}

export default ReviewInvoice;