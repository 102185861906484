export const itemsMerchant = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        exact: true
    },
    {
        title: 'Loans',
        path: 'loans',
        submenu: [
            // {
            //     title: 'Manage Loans',
            //     path: '/loans/all'
            // },
            {
                title: 'Apply',
                path: '/loans/apply'
            },
            {
                title: 'View Loans ',
                path: '/loans'
            },
        ]
    },
    // {
    //     title: 'Trade Activities',
    //     path: '/ct/loans/trade-activities',
    //     exact: true
    // },
    {
        title: 'Trade',
        path: '/trades',
        exact: true
    },
    {
        title: 'Invoicing',
        path: '/invoicing/new',
        exact: true
    },
    {
        title: 'Settings',
        path: '/settings',
        // submenu: [
        //     // {
        //     //     title: 'Manage Loans',
        //     //     path: '/loans/all'
        //     // },
        //     {
        //         title: 'Profile',
        //         path: '/settings/profile'
        //     },
        //     {
        //         title: 'Business ',
        //         path: '/settings/business'
        //     },
        // ]
    },

]

export const items = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        exact: true
    },
    {
        title: 'Loans',
        path: 'loans',
        submenu: [
            {
                title: 'Manage Loans',
                path: '/loans/all'
            },
            // {
            //     title: 'Apply',
            //     path: '/loans/apply'
            // },
            // {
            //     title: 'View Loans ',
            //     path: '/loans'
            // },
            // {
            //     title: 'Trade Activities ',
            //     path: '/loans/trade-activities'
            // }
        ]
    },
    // {
    //     title: 'Trade Activities',
    //     path: '/ct/loans/trade-activities',
    //     exact: true
    // },
    {
        title: 'Trade Activities',
        path: '/trade-activities',
        exact: true
    },
    {
        title: 'Trade',
        path: '/trades',
        exact: true
    },
    {
        title: 'Invoicing',
        path: '/invoicing/new',
        exact: true
    },
    {
        title: 'Payments',
        path: '/payments',
        exact: true
    },
    {
        title: 'Settings',
        path: '/settings',
        exact: true,
        // submenu: [
        //     {
        //         title: 'Sourcing Partners',
        //         path: '/sourcing-partners'
        //     },
        //     {
        //         title: 'Commodities',
        //         path: '/commodities'
        //     },
        //     {
        //         title: 'Users',
        //         path: '/users'
        //     },
        //     {
        //         title: 'Profile',
        //         path: '/settings/profile'
        //     },
        // ]
    },

]